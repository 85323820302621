import { Table } from "antd";
import React, { useEffect, useState } from "react";

const OverviewTable = ({ data }) => {
  const [dailyStats, setDailyStats] = useState([]);

  useEffect(() => {
    setDailyStats(data);
  }, [data]);

  const checkValue = (value) => {
    if (!value && value !== 0) return "---"; // Empty or undefined
    if (isNaN(value) || !isFinite(value)) return "---"; // NaN or Infinity
    return value; // Valid number
  };
  const columnsOption = [
    {
      title: "Portfolio Overview",
      dataIndex: "portfolioOverview",
      key: "portfolioOverview",
      width: 250,
    },
    {
      title: "Trade Data",
      dataIndex: "tradeData",
      key: "tradeData",
      width: 266,
    },
    {
      title: "Journal Data",
      dataIndex: "journalData",
      key: "journalData",
      width: 266,
    },
  ];
  const dataSet = [
    {
      portfolioOverview: "Average Daily Volume",
      tradeData: checkValue(
        (
          dailyStats.reduce(
            (sum, item) => sum + parseFloat(item.volume || 0),
            0
          ) / dailyStats.length
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average winning Trades",
      tradeData: checkValue(
        (
          dailyStats.reduce(
            (sum, item) => sum + parseFloat(item.winningTrades || 0),
            0
          ) / dailyStats.length
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Losing Trade",
      tradeData: checkValue(
        (
          dailyStats.reduce(
            (sum, item) => sum + parseFloat(item.losingTrades || 0),
            0
          ) / dailyStats.length
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Total Number of Trades",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) => sum + parseFloat(item.totalTrades || 0),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Number of Winning Trades",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) => sum + parseInt(item.winningTrades || 0, 10),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Number of Breakeven Trades",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) => sum + parseInt(item.totalBreakEvenTrades || 0, 10),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Max Consecutive Wins",
      tradeData: checkValue(
        Math.max(
          ...dailyStats.map((item) =>
            parseInt(item.maxConsecutiveWins || 0, 10)
          )
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Max Consecutive Losses",
      tradeData: checkValue(
        Math.max(
          ...dailyStats.map((item) =>
            parseInt(item.maxConsecutiveLosses || 0, 10)
          )
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Total Commission",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) => sum + parseFloat(item.totalCommission || 0),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Total Fees",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) =>
            sum +
            parseFloat(item.totalCommission || 0) +
            parseFloat(item.totalSwap || 0),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Total Swap",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) => sum + parseFloat(item.totalSwap || 0),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Largest Profit",
      tradeData: checkValue(
        Math.max(
          ...dailyStats.map((item) => parseFloat(item.largestProfit || 0))
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Largest Loss",
      tradeData: checkValue(
        Math.min(...dailyStats.map((item) => parseFloat(item.largestLoss || 0)))
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Trade P&L",
      tradeData: checkValue(
        (
          dailyStats.reduce((sum, item) => sum + parseFloat(item.pal || 0), 0) /
          dailyStats.reduce(
            (sum, item) => sum + parseInt(item.totalTrades || 0, 10),
            0
          )
        ).toFixed(5)
      ),
      journalData: "",
    },
    // { portfolioOverview: "Profit Factor", tradeData: "", journalData: "" },
    {
      portfolioOverview: "Open Trade",
      tradeData: checkValue(
        dailyStats.reduce(
          (sum, item) => sum + parseInt(item.openTrades || 0, 10),
          0
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Total Trading Days",
      tradeData: checkValue(
        new Set(
          dailyStats.map(
            (item) => new Date(item.date).toISOString().split("T")[0]
          )
        ).size
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Winning Days",
      tradeData: checkValue(
        dailyStats.filter((item) => parseFloat(item.pal || 0) > 0).length
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Max Consecutive Winning Days",
      tradeData: checkValue(
        Math.max(
          ...dailyStats.reduce(
            (streaks, item) => {
              const isWinningDay = parseFloat(item.pal || 0) > 0;
              if (isWinningDay) streaks[streaks.length - 1]++;
              else streaks.push(0);
              return streaks;
            },
            [0]
          )
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Max Consecutive Losing Days",
      tradeData: checkValue(
        Math.max(
          ...dailyStats.reduce(
            (streaks, item) => {
              const isLosingDay = parseFloat(item.pal || 0) < 0;
              if (isLosingDay) streaks[streaks.length - 1]++;
              else streaks.push(0);
              return streaks;
            },
            [0]
          )
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Daily P&L",
      tradeData: checkValue(
        (
          dailyStats.reduce((sum, item) => sum + parseFloat(item.pal || 0), 0) /
          dailyStats.length
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Winning Day P&L",
      tradeData: checkValue(
        (
          dailyStats
            .filter((item) => parseFloat(item.pal || 0) > 0)
            .reduce((sum, item) => sum + parseFloat(item.pal || 0), 0) /
          dailyStats.filter((item) => parseFloat(item.pal || 0) > 0).length
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Losing Day P&L",
      tradeData: checkValue(
        ((losingDays) =>
          losingDays.length
            ? losingDays.reduce(
                (sum, item) => sum + parseFloat(item.pal || 0),
                0
              ) / losingDays.length
            : 0)(
          dailyStats.filter((item) => parseFloat(item.pal || 0) < 0)
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Largest Profitable Day",
      tradeData: checkValue(
        Math.max(
          ...dailyStats
            .filter((item) => parseFloat(item.pal || 0) > 0)
            .map((item) => parseFloat(item.pal || 0))
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Largest Losing Day",
      tradeData: checkValue(
        Math.min(
          ...dailyStats
            .filter((item) => parseFloat(item.pal || 0) < 0)
            .map((item) => parseFloat(item.pal || 0))
        )
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Max Drawdown",
      tradeData: checkValue(
        Math.min(...dailyStats.map((item) => parseFloat(item.drawdown || 0)))
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Max Drawdown %",
      tradeData: checkValue(
        Math.min(
          ...dailyStats.map((item) => {
            const drawdownValue = parseFloat(item.drawdown || 0);
            const peakValue = parseFloat(item.dayStartEquity || 0); // Or use dayEndEquity if more appropriate
            return peakValue !== 0 ? (drawdownValue / peakValue) * 100 : 0;
          })
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Drawdown",
      tradeData: checkValue(
        (
          dailyStats.reduce(
            (sum, item) => sum + parseFloat(item.drawdown || 0),
            0
          ) / dailyStats.length
        ).toFixed(5)
      ),
      journalData: "",
    },
    {
      portfolioOverview: "Average Drawdown %",
      tradeData: checkValue(
        (
          dailyStats.reduce((sum, item) => {
            const drawdownValue = parseFloat(item.drawdown || 0);
            const peakValue = parseFloat(item.dayStartEquity || 0); // Use dayEndEquity if more appropriate
            if (peakValue !== 0) {
              return sum + (drawdownValue / peakValue) * 100;
            }
            return sum;
          }, 0) / dailyStats.length
        ).toFixed(5)
      ),
      journalData: "",
    },
  ];

  return (
    <div className="trading-overview-table">
      <Table columns={columnsOption} dataSource={dataSet} pagination={false} />
      <br />
      <br />
    </div>
  );
};

export default OverviewTable;
