import React, { useEffect, useState } from "react";
import { updateEmailToken, verifyEmail } from "../api/auth";
import { useHistory } from "react-router-dom";
import { Button } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const VerifyEmail = () => {
  const history = useHistory();
  const [emailExist, setEmailExist] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [timer, setTimer] = useState(60);

  const handleButtonClick = () => {
    setIsButtonDisabled(true);
    setShowTimer(true);

    updateEmailToken(emailExist)
      .then((response) => {
        if (response.data.message === "Email token is refreshed") {
          console.log(response.data.message);
        }
      })
      .catch((error) => {
        // Handle the error if needed
        console.log(error);
      });
  };

  useEffect(() => {
    const checkemail = async () => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      const emailToken = urlParams.get("emailToken");
      const email = urlParams.get("email");
      if (emailToken) {
        verifyEmail(emailToken).then((response) => {
          if (response.data.message === "User email verified") {
            console.log(response.data.message);
            history.push(`/login`);
          } else if (response.data.message === "Unauthorized access") {
            // Handle unauthorized access here
            console.log("Unauthorized access");
            history.push(`/email-expire`);
          }
        });
      }
      if (email) {
        setEmailExist(email);
      }
    };
    checkemail();
    const intervalId = setInterval(() => {
      checkemail();
    }, 0.1 * 60 * 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  useEffect(() => {
    let intervalId;
    if (showTimer) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearInterval(intervalId);
  }, [showTimer]);

  useEffect(() => {
    let timeoutId;
    if (timer === 0) {
      setShowTimer(false);
      setIsButtonDisabled(false);
      setTimer(60);
    } else {
      timeoutId = setTimeout(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);
    }
    return () => clearTimeout(timeoutId);
  }, [timer]);
  return (
    <div className="verifyEmailPage">
      {emailExist ? (
        <>
          <div style={{ padding: "2rem 0", maxWidth: "500px" }}>
            <br />
            <h2 style={{ textAlign: "center", mt: "2rem" }}>
              Verify Your Email
            </h2>
            {/* Check your email & click on link to activate your account. */}
            <p style={{ textAlign: "center" }}>
              You will need to verify your email to complete registration
            </p>
            <br></br>
            <img
              src={"/images/Email-Verification.png"}
              style={{
                textAlign: "center",
                display: "block",
                margin: "0 auto",
                // width: "50%",
              }}
              width={400}
              alt="img"
            />
            <br />
            {!showTimer && (
              <Button
                color="primary"
                fullWidth
                size="large"
                type="submit"
                style={{
                  background: "#1890ff",
                  padding: "1rem 0",
                  width: "100%",
                }}
                // variant="contained"
                disabled={isButtonDisabled}
                onClick={handleButtonClick}
              >
                Resend Email
              </Button>
            )}
            {showTimer && (
              <div
                style={{
                  textAlign: "center",
                  color: "#5048E5",
                  fontWeight: "bold",
                  fontSize: "1.0rem",
                }}
              >
                Please wait {timer} seconds before sending another email.
              </div>
            )}

            <br />
            <br />
            <p color="textSecondary">
              An email has been sent to {emailExist || ''} to verify your account. If
              you have not received the email after a few minutes, please check
              your spam folder.Please note that this link is valid for 30
              minutes.
            </p>

            <br />

            <p color="textSecondary" variant="body2">
              Enter new email account? <Link to="/sign-up">Sign Up</Link>
            </p>
          </div>
        </>
      ) : (
        <>
          {" "}
          <h2>Verifying Your Email...</h2>
          <div>
            <img
              src="/images/verify-email.jpg"
              width={700}
              alt="verify-email"
            />
          </div>
        </>
      )}
    </div>
  );
};

export default VerifyEmail;
