import { Button, Col, DatePicker, Row } from "antd";
import { useRef, useState } from "react";
import Breadcrumbs from "../components/Breadcrumb/breadcrumb";
import TabsComponent from "../components/Tabs/tabs";
import { rangePresets } from "../components/TradeHistory/dateRanges";
const { RangePicker } = DatePicker;

const calender = [
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 50 47.17"
  >
    <defs>
      <style>
        {`.cls-211 {
        fill:#0062FF;
        stroke-width: 0px;
      }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-211"
          d="M50,15.93v.42H0v-.42C0,8.71,5.86,2.85,13.1,2.85h.91V0h2.74v2.85h16.69V0h2.74v2.85h.74c7.22,0,13.08,5.86,13.08,13.08Z"
        />
        <path
          class="cls-211"
          d="M0,19.28v20.09c0,4.31,3.49,7.8,7.8,7.8h34.4c4.31,0,7.8-3.49,7.8-7.8v-20.09H0ZM18.03,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM27.79,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM37.55,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47Z"
        />
      </g>
    </g>
  </svg>,
];


function Reports() {
  const [dateVisible, setDateVisible] = useState(false);
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const [selectedDates, setSelectedDates] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const dateRangeRef = useRef(dateRange);


  const handleButtonClick = () => {
    setDropdownVisible(true); // Show dropdown when button is clicked
    setDateVisible(true);
  };
  const onRangeChange = (dates, dateStrings) => {
    console.log("-------------------");
    // setDropdownVisible(true); // Hide the dropdown after selecting dates
    setDateVisible(true)
    // setDropdownVisible(false); // Hide dropdown if it is closed

    if (dates) {
      // console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDateRange({ fromDate: dateStrings[0], toDate: dateStrings[1] });
      const updatedRange = { fromDate: dateStrings[0], toDate: dateStrings[1] };
      dateRangeRef.current = updatedRange;
    } else {
      console.log("Clear");
      setDateVisible(false)
    }
    setSelectedDates(dates);
    // setDateVisible(false);
  };
  const handleDropdownChange = (open) => {
    if (!open) {
      setDropdownVisible(false); // Hide dropdown if it is closed
    }
  };
  return (
    <>
      <div>
        <Row gutter={[24, 0]}>
          <Col span={24} md={12}>
            <Breadcrumbs />
          </Col>
          <Col span={24} md={12} style={{ display: "flex", justifyContent: "end" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              {!dateVisible && (
                <Button
                  onClick={handleButtonClick}
                  style={{
                    color: "#0062FF",
                    borderColor: "#0062FF",
                    marginRight: "20px",
                    fontWeight: "600",
                    fontSize: "15px",
                    height: "40px",
                  }}
                >
                  {calender} Date Range
                </Button>
              )}
              {dateVisible && (
                <RangePicker
                  value={selectedDates}
                  presets={rangePresets}
                  onClick={() => setDropdownVisible(true)}
                  onChange={onRangeChange}
                  onOpenChange={handleDropdownChange} // Handle dropdown open/close
                  open={dropdownVisible} // Control the dropdown visibility
                  className="date-range-picker"
                  style={{
                    marginRight: "20px",
                    fontWeight: "600",
                    fontSize: "15px",
                  }}
                />
              )}
            </div>
          </Col>
        </Row>
        <TabsComponent dateRange={dateRange} />
      </div>
    </>
  );
}

export default Reports;
