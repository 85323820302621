import dayjs from "dayjs";

export const rangePresets = [
    {
      label: "Today",
      value: [dayjs().startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Yesterday",
      value: [
        dayjs().subtract(1, "day").startOf("day"),
        dayjs().subtract(1, "day").endOf("day"),
      ],
    },
    {
      label: "Weekly",
      value: [dayjs().startOf("week"), dayjs().endOf("week")],
    },
    {
      label: "Last 15 Days",
      value: [dayjs().subtract(15, "day").startOf("day"), dayjs().endOf("day")],
    },
    {
      label: "Monthly",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last 6 Months",
      value: [
        dayjs().subtract(6, "month").startOf("day"),
        dayjs().endOf("day"),
      ],
    },
    {
      label: "Yearly",
      value: [dayjs().startOf("year"), dayjs().endOf("year")],
    },
  ];