const lineChart = {
  series: [
    {
      name: "Mobile apps",
      data: [350, 40, 300, 220, 500, 250, 400, 230, 500],
    },
    {
      name: "Websites",
      data: [30, 90, 40, 140, 290, 290, 340, 230, 400],
    },
  ],

  options: {
    chart: {
      width: "100%",
      height: 350,
      type: "line",
      toolbar: { show: false },
    },
    legend: { show: false },
    dataLabels: { enabled: false },
    stroke: {
      width: 3,
      curve: "smooth",
      colors: ["rgba(85, 66, 246, 0.56)", "rgba(32, 201, 172, 0.56)"],
    },

    // Use markers.colors to set color for each marker
    markers: {
      size: 5,
      colors: ["rgb(85, 66, 246)", "rgb(32, 201, 172)"], // Set color for each series marker explicitly
      strokeColors: ["white", "white"],
      strokeWidth: 2,
      hover: {
        size: 7,
      },
    },

    yaxis: {
      labels: {
        style: { colors: ["#667085"] },
        formatter: (val) => `$ ${val}`,
      },
    },

    xaxis: {
      labels: {
        style: { fontSize: "12px", colors: Array(9).fill("#667085") },
      },
      categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct"],
    },

    tooltip: {
      y: {
        formatter: (val) => `$ ${val}`,
      },
    },
  },
};

export default lineChart;
