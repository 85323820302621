// components/ProtectedRoute.js
import { Route, Redirect } from "react-router-dom";
import Main from "./layout/Main";

// Mock authentication function, replace with actual logic
const isAuthenticated = () => {
    // For example, check if a token exists in localStorage
    return !!localStorage.getItem("accessToken");
};

const ProtectedRoute = ({ component: Component, ...rest }) => (
    <Route
        {...rest}
        render={(props) =>
            isAuthenticated() ? (
                <Main>
                    <Component {...props} />
                </Main>
            ) : (
                <Redirect to="/sign-in" />
            )
        }
    />
);

export default ProtectedRoute;
