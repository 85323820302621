import axios from 'axios'
import { jwtDecode } from 'jwt-decode';


// Function to decode token and check remaining time
const isTokenExpiringSoon = (token) => {
    try {
        const decodedToken = jwtDecode(token);
        const currentTime = Math.floor(Date.now() / 1000);  // Get current time in seconds
        const timeRemaining = currentTime - decodedToken.exp;
        console.log('decodedToken', timeRemaining);

        // If token has more than 15 minutes remaining (900 seconds), log out user
        return timeRemaining > 900;  // 900 seconds = 15 minutes
    } catch (error) {
        return false; // Return false if there was an error decoding the token
    }
};
console.log('setEnvs', process.env.REACT_APP_SERVER_URL);

// Function to refresh the access token
const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
        return null;
    }

    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/refresh-token`, { token: refreshToken });
        if (res.status === 200 && res.data.accessToken) {
            localStorage.setItem("accessToken", res.data.accessToken);
            return res.data.accessToken;
        }
    } catch (error) {
        console.log("Error refreshing token:", error);
        return null;
    }
};

axios.interceptors.request.use((config) => {
    const accessToken = localStorage.getItem("accessToken");
    if (accessToken) {
        config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(
    (response) => response,
    async (error) => {
        if ((error.response.status === 401 || error.response.status === 403)) {
            const accessToken = localStorage.getItem('accessToken');

            if (accessToken && isTokenExpiringSoon(accessToken)) {
                // Logout if token expires in more than 15 minutes
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("userID");
                localStorage.removeItem("loginID");
                window.location.href = '/sign-in';  // Redirect to login
                return;
            }

            // Token has expired or is close to expiration, attempt to refresh it
            const newAccessToken = await refreshToken();
            if (newAccessToken) {
                // Retry the original request with the new access token
                localStorage.setItem('accessToken', newAccessToken);
                const config = error.config;
                config.headers['Authorization'] = `Bearer ${newAccessToken}`;
                return axios(config);
            } else {
                // Logout if refresh token fails
                localStorage.removeItem("accessToken");
                localStorage.removeItem("refreshToken");
                localStorage.removeItem("userID");
                localStorage.removeItem("loginID");
                window.location.href = '/sign-in';  // Redirect to login
            }
        }
        return Promise.reject(error);
    }
);

export const addAccount = async (values) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/addAccount`, values)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during addAccount." };
        }
    }
}

export const getBrokerList = async () => {
    try {

        const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/getBroker`)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during addAccount." };
        }
    }
}

export const getTradesHistory = async (loginID, fromDate, toDate) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/fetchTrades/${loginID}`, { fromDate, toDate })
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during addAccount." };
        }
    }
}

export const getAccounts = async (userID) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/accounts/${userID}`)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during addAccount." };
        }
    }
}

export const deleteAccount = async (userID, deleteData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/account/delete/${userID}`, deleteData)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during deleteAccounts." };
        }
    }
}

export const editAccount = async (userID, editData) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/account/edit/${userID}`, editData)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during editAccount." };
        }
    }
}

export const getNotifications = async (userID) => {
    try {
        const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/notifications-get/${userID}`)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during get notifications." };
        }
    }
}

export const updateNotificationStatus = async (notificationID) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/notification-update/${notificationID}`)
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during updateNotificationStatus." };
        }
    }
}

// Currently not use
export const getProfitLossReportData = async (loginID, fromDate, toDate) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/get-profit-loss-report`, { loginID, fromDate, toDate })
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during getProfitLossReportData." };
        }
    }
}

export const getDailyStatsData = async (loginID, fromDate, toDate) => {
    try {
        const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/get/dailystats/${loginID}`, { fromDate, toDate })
        return res
    } catch (error) {
        if (error.response) {
            return error.response;
        } else {
            return { error: error.message || "An error occurred during getDailyStatsData." };
        }
    }
}

