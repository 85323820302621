import { Typography } from "antd";
import ReactApexChart from "react-apexcharts";
import lineChart from "./configs/lineChart";

function LineChart() {
  const { Title, Paragraph } = Typography;

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Equity Growth</Title>
          <p className="lastweek">
            than last week <span className="bnb2">+30%</span>
          </p>
        </div>
        <div className="sales chart-portfolio-btn">
          Portfolio
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={350}
        width={"100%"}
      />
    </>
  );
}

export default LineChart;
