import { useEffect } from "react";

import {
  Button,
  Col,
  Dropdown,
  List,
  Row,
  Typography
} from "antd";

import {
  LogoutOutlined
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import 'resize-observer-polyfill';
import Accounts from "../Accounts/accounts";
import Notification from "../Notificaions/Notification";

const profile = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM12 7C12 8.10457 11.1046 9 10 9C8.89543 9 8 8.10457 8 7C8 5.89543 8.89543 5 10 5C11.1046 5 12 5.89543 12 7ZM9.99993 11C7.98239 11 6.24394 12.195 5.45374 13.9157C6.55403 15.192 8.18265 16 9.99998 16C11.8173 16 13.4459 15.1921 14.5462 13.9158C13.756 12.195 12.0175 11 9.99993 11Z"
      fill="#111827"
    ></path>
  </svg>,
];

const toggler = [
  <svg
    width="20"
    height="20"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
    key={0}
  >
    <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
  </svg>,
];


function Header({
  placement,
  name,
  subName,
  onPress,
  handleSidenavColor,
  handleSidenavType,
  handleFixedNavbar,
}) {
  const { Title } = Typography;

  useEffect(() => window.scrollTo(0, 0));

  const userEmail = localStorage.getItem('userEmail')
  const userID = localStorage.getItem('userID')
  const profileMenu = (
    <List
      min-width="100%"
      style={{ background: "white", width: '240px', }}
      className="header-notifications-dropdown "
      itemLayout="horizontal"
    >
      <List.Item className="accounts-dropdown-item" style={{ margin: "0 0px", padding: "12px" }}>
        <List.Item.Meta
          style={{ alignItems: "center", textTransform: "" }}
          className="user-mail"
          title={userEmail}
        />
      </List.Item>
      {/* <List.Item className="accounts-dropdown-item" style={{ margin: "0 0px", padding: "8px" }}>
        <List.Item.Meta
          style={{ alignItems: "center" }}

          title={userID}
        />
      </List.Item> */}
      <List.Item className="accounts-dropdown-item" style={{ margin: "0 0px", padding: "8px" }}>
        <span onClick={() => {
          localStorage.removeItem("accessToken")
          localStorage.removeItem("refreshToken")
          localStorage.removeItem("userEmail")
          localStorage.removeItem("userID")
          localStorage.removeItem("loginID")
        }}>
          <Link to="/sign-in" className="btn-sign-in">
            <LogoutOutlined /> <span>Sign out</span>
          </Link>
        </span>
      </List.Item>
    </List>
  );

  return (
    <>
      <Row gutter={[24, 0]}>

        <Col span={24} md={24} className="header-control">
          <Notification />
          <Button
            type="link"
            className="sidebar-toggler"
            onClick={() => onPress()}
          >
            {toggler}
          </Button>

          <Dropdown
            overlay={profileMenu}
            trigger={["hover"]}
            placement="bottom"
          >
            <span style={{ margin: "0 15px" }}>

              {profile}
            </span>
          </Dropdown>
          <Accounts />
        </Col>
      </Row>
    </>
  );
}

export default Header;
