export function formatTime(isoTime) {
    if (isoTime !== 'null' && isoTime !== 'undefined') {
        console.log('isoTime', typeof isoTime);

        const date = new Date(isoTime);
        const now = new Date();

        const timeDifference = now - date; // Difference in milliseconds
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        if (seconds < 60) {
            return `${seconds} sec ago`;
        } else if (minutes < 60) {
            return `${minutes} min ago`;
        } else if (hours < 24) {
            return `${hours} hr ago`;
        } else if (days === 1) {
            return "Yesterday";
        } else if (days < 7) {
            // Display day name for notifications within the last week
            return dayNames[date.getDay()];
        } else {
            // Display full date in MM-DD-YY format for older notifications
            const month = (date.getMonth() + 1).toString().padStart(2, "0");
            const day = date.getDate().toString().padStart(2, "0");
            const year = date.getFullYear().toString().slice(-2);
            return `${month}-${day}-${year}`;
        }
    } else return null
}
export function calculateCumulativePAL(data, fromDate, toDate) {
    // Convert fromDate and toDate to Date objects
    const from = new Date(fromDate);
    const to = new Date(toDate);

    // Filter data within the date range and sum up the 'pal' values
    const cumulativePAL = data
        .filter((entry) => {
            const entryDate = new Date(entry.date);
            return entryDate >= from && entryDate <= to;
        })
        .reduce((sum, entry) => sum + parseFloat(entry.pal), 0);

    return cumulativePAL.toFixed(2); // Return cumulative PAL as a string with 2 decimal places
}