import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Modal,
  Radio,
  Row,
  Table,
  Tag,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { getTradesHistory } from "../../api/api";
import ResponsivePagination from "../Responsive/responsivePagination";
import ResponsiveScroll from "../Responsive/responsiveScroll";
import { columnsOption, customTitles, defaultColumns } from "./columns";
import { rangePresets } from "./dateRanges";
import { formatTime } from "../../utils/utils";
import Breadcrumbs from "../Breadcrumb/breadcrumb";
const { RangePicker } = DatePicker;
const filter = [
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 50 51.27"
  >
    <defs>
      <style>
        {`.cls-1 {
        fill: #0062FF;
        stroke-width: 0px;
      }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <path
        class="cls-1"
        d="M49.41,3.69c-2.49,3.51-4.98,7.02-7.48,10.53-2.96,4.18-5.91,8.36-8.9,12.52-.79,1.11-1.16,2.29-1.16,3.64.02,4.92.01,9.84,0,14.77,0,1.43-.36,1.97-1.71,2.48-3.14,1.19-6.29,2.38-9.44,3.57-.03.01-.05.04-.08.06h-.9c-.37-.24-.79-.43-1.11-.73-.51-.48-.57-1.14-.57-1.81,0-6.09-.02-12.19.02-18.27,0-1.43-.39-2.66-1.22-3.82C11.45,19.01,6.03,11.36.6,3.71-.14,2.68-.19,1.74.42.89.81.34,1.37.08,2.03.01,2.2,0,2.37,0,2.53,0h44.96c1.33,0,2.13.54,2.43,1.64.21.78-.07,1.43-.51,2.05Z"
      />
    </g>
  </svg>,
];
const calender = [
  <svg
    id="Layer_2"
    data-name="Layer 2"
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="13"
    viewBox="0 0 50 47.17"
  >
    <defs>
      <style>
        {`.cls-21 {
        fill:#0062FF;
        stroke-width: 0px;
      }`}
      </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
      <g>
        <path
          class="cls-21"
          d="M50,15.93v.42H0v-.42C0,8.71,5.86,2.85,13.1,2.85h.91V0h2.74v2.85h16.69V0h2.74v2.85h.74c7.22,0,13.08,5.86,13.08,13.08Z"
        />
        <path
          class="cls-21"
          d="M0,19.28v20.09c0,4.31,3.49,7.8,7.8,7.8h34.4c4.31,0,7.8-3.49,7.8-7.8v-20.09H0ZM18.03,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM27.79,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM37.55,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47Z"
        />
      </g>
    </g>
  </svg>,
];

const TradesHistory = (props) => {
  const [data, setData] = useState([]);
  const [allColumns, setAllColumns] = useState([]); // Store all columns
  const [visibleColumns, setVisibleColumns] = useState([]); // Columns currently visible in the table
  const [selectedColumns, setSelectedColumns] = useState([]); // Columns selected via checkboxes (default columns initially)
  const [childData, setChildData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [dateVisible, setDateVisible] = useState(false);
  const [timeAgo, setTimeAgo] = useState(null);
  const [pageSize, setPageSize] = useState(10); // Default rows per page
  const [scroll, setScroll] = useState({ x: "max-content", y: 500 }); // Default scroll values
  const [dateRange, setDateRange] = useState({ fromDate: "", toDate: "" });
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const [filteredData, setFilteredData] = useState([]); // Data after filtering by Radio selection
  const [selectedFilter, setSelectedFilter] = useState("history"); // Default to 'History'
  const [tradeCounts, setTradeCounts] = useState({
    openTrades: 0,
    openOrders: 0,
    history: 0,
  });
  const storeColumns = [];
  const dateRangeRef = useRef(dateRange);

  useEffect(() => {
    // Load selected columns from localStorage if they exist
    const savedColumns = localStorage.getItem("selectedColumns");
    if (savedColumns) {
      const parseColumns = JSON.parse(savedColumns);
      setSelectedColumns(parseColumns);
    } else {
      setSelectedColumns(defaultColumns);
    }

    fetchData();

    const intervalId = setInterval(() => {
      fetchData();
      const lastUpdate = localStorage.getItem("lastUpdates");
      setTimeAgo(formatTime(lastUpdate));
    }, 8000);
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    if (allColumns.length > 0) {
      setVisibleColumns(
        allColumns.filter((col) => selectedColumns.includes(col.key))
      );
    }
  }, [selectedColumns, allColumns]);

  useEffect(() => {
    fetchData();
  }, [dateRange]);

  useEffect(() => {
    applyFilter();
  }, [selectedFilter, data]);

  const fetchData = () => {
    const loginID = localStorage.getItem("loginID");
    if (loginID) {
      getTradesHistory(
        loginID,
        dateRangeRef.current.fromDate,
        dateRangeRef.current.toDate
      )
        .then((res) => {
          console.log(res?.data?.length);
          
          if (res?.data?.length > 0) {
            const tradesData = res.data.map((trade) => ({
              ...trade,
              openTime: trade.openTime
                ? formatDateToLocal(trade.openTime)
                : "-- -- --",
              closeTime: trade.closeTime
                ? formatDateToLocal(trade.closeTime)
                : "-- -- --",
              closePrice: trade.closePrice || "---",
              outcome: trade.pal < 0 ? "loss" : "profit", // Define outcome
            }));

            if (storeColumns.length === 0) {
              const columnsData = Object.keys(tradesData[0])
                .filter(
                  (key) =>
                    key !== "tradeID" &&
                    key !== "loginID" &&
                    key !== "parentTicket"
                )
                .map((key) => ({
                  title: customTitles[key],
                  dataIndex: key,
                  key: key,
                  width: 110,
                  render: (text) => {
                    if (key === "type") {
                      return (
                        <Tag color={text === "BUY" ? "green" : "red"}>
                          {text.toUpperCase()}
                        </Tag>
                      );
                    } else if (key === "pal") {
                      return (
                        <span
                          style={{
                            color: text < 0 ? "#FF5928" : "#069A6E",
                            fontWeight: "500",
                          }}
                        >
                          {text > 0 && "+"}
                          {text}
                        </span>
                      );
                    } else if (key === "openTime") {
                      return (
                        <span
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {text}
                        </span>
                      );
                    } else if (key === "closeTime") {
                      return (
                        <span
                          style={{
                            fontWeight: "500",
                          }}
                        >
                          {text}
                        </span>
                      );
                    } else if (key === "symbol") {
                      return (
                        <span style={{ color: "#0062FF", fontWeight: "500" }}>
                          {text}
                        </span>
                      );
                    } else if (key === "gainPercentage") {
                      return (
                        <span
                          style={{
                            color: +text > 0 ? "#069A6E" : "#FF5928",
                            fontWeight: "500",
                          }}
                        >
                          {+text > 0 ? "+" : "--"}
                          {text}
                          {+text ? "%" : ""}
                        </span>
                      );
                    } else if (key === "outcome") {
                      return text === "profit" ? (
                        <CheckOutlined
                          style={{
                            background: "#069A6E",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "4px",
                            borderRadius: "50%",
                            marginLeft: "10px",
                          }}
                        />
                      ) : (
                        <CloseOutlined
                          style={{
                            background: "#FF5928",
                            color: "white",
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "4px",
                            borderRadius: "50%",
                            marginLeft: "10px",
                          }}
                        />
                      );
                    }
                    return <span style={{ fontWeight: "400" }}>{text}</span>;
                  },
                }));

              // Add "Outcome" column after "Symbol" column

              const reorderedColumns = [
                ...columnsData.filter((col) => col.key === "openTime"), // "Open Date" column
                ...columnsData.filter((col) => col.key === "symbol"), // "Symbol" column
                ...columnsData.filter((col) => col.key === "outcome"), // "Outcome" column
                ...columnsData.filter((col) => col.key === "closeTime"), // "Outcome" column
                ...columnsData.filter((col) => col.key === "openPrice"), // "Outcome" column
                ...columnsData.filter((col) => col.key === "closePrice"), // "Outcome" column
                ...columnsData.filter((col) => col.key === "gainPercentage"), // "Outcome" column
                ...columnsData.filter(
                  (col) =>
                    col.key !== "openTime" &&
                    col.key !== "symbol" &&
                    col.key !== "outcome" &&
                    col.key !== "closeTime" &&
                    col.key !== "openPrice" &&
                    col.key !== "closePrice" &&
                    col.key !== "gainPercentage"
                ), // Remaining columns
              ];

              setAllColumns(reorderedColumns);
              setVisibleColumns(
                columnsData.filter((col) => selectedColumns.includes(col.key))
              );
              storeColumns.push(columnsData);
            }

            const parentData = tradesData.filter(
              (item) => item.parentTicket === "0"
            );
            setData(parentData);
            calculateCounts(parentData);
            setChildData(
              tradesData.filter((item) => item.parentTicket !== "0")
            );
          } else {
            setAllColumns(columnsOption);
            setData([]);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const formatDateToLocal = (dateString) => {
    const date = new Date(dateString);
    return date
      .toLocaleString("en-US", {
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false, // This will format it to AM/PM
      })
      .replace(/\//g, ".")
      .replace(",", ""); // Remove the comma; // Replace '/' with '.';
  };

  const showModal = () => {
    setVisible(true);
  };

  const handleOk = () => {
    const newVisibleColumns = allColumns.filter((col) =>
      selectedColumns.includes(col.key)
    );
    setVisibleColumns(newVisibleColumns); // Only set the visible columns
    localStorage.setItem("selectedColumns", JSON.stringify(selectedColumns)); // Save selected columns to localStorage
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const onCheckboxChange = (checkedValues) => {
    setSelectedColumns(checkedValues); // Update selected columns
  };

  // Handle "All", "None", and "Default" selections
  const selectAll = () => {
    setSelectedColumns(allColumns.map((col) => col.key));
  };

  const selectNone = () => {
    setSelectedColumns([]);
  };

  const selectDefault = () => {
    setSelectedColumns(defaultColumns);
  };

  const isAllSelected = selectedColumns.length === allColumns.length;
  const isNoneSelected = selectedColumns.length === 0;
  const isDefaultSelected =
    selectedColumns.sort().toString() === defaultColumns.sort().toString();

  const onRangeChange = (dates, dateStrings) => {
    console.log("-------------------");
    // setDropdownVisible(true); // Hide the dropdown after selecting dates
    if (dates) {
      console.log("From: ", dates[0], ", to: ", dates[1]);
      console.log("From: ", dateStrings[0], ", to: ", dateStrings[1]);
      setDateRange({ fromDate: dateStrings[0], toDate: dateStrings[1] });
      const updatedRange = { fromDate: dateStrings[0], toDate: dateStrings[1] };
      dateRangeRef.current = updatedRange;
    } else {
      console.log("Clear");
    }
    setSelectedDates(dates);
    setDateVisible(false);
  };
  const handleButtonClick = () => {
    setDropdownVisible(true); // Show dropdown when button is clicked
    setDateVisible(true);
  };

  const handleDropdownChange = (open) => {
    if (!open) {
      setDropdownVisible(false); // Hide dropdown if it is closed
    }
  };

  const applyFilter = () => {
    let newFilteredData = data;
    if (selectedFilter === "openTrades") {
      newFilteredData = data.filter((trade) => trade.closeTime === "-- -- --");
    } else if (selectedFilter === "openOrders") {
      newFilteredData = data.filter((trade) => trade.openTime === "-- -- --");
    }
    setFilteredData(newFilteredData);
  };

  const calculateCounts = (tradesData) => {
    const openTrades = tradesData.filter(
      (trade) => trade.closeTime === "-- -- --"
    ).length;
    const openOrders = tradesData.filter(
      (trade) => trade.openTime === "-- -- --"
    ).length;
    const history = tradesData.length;
    setTradeCounts({ openTrades, openOrders, history });
  };
  return (
    <div>
      <div className="tabled">
        <Row gutter={[24, 0]}>
          <Col span={24} md={12}>
            <Breadcrumbs />
          </Col>
        </Row>
        <Row gutter={[24, 0]}>
          {/* <Col> */}
          <Card
            style={{ width: "100%", marginLeft: "15px", boxShadow: "none" }}
            bordered={false}
            className="criclebox tablespace mb-24"
            title={
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <span>Trades History</span>

                <div style={{ display: "flex" }}>
                  <div
                    style={{
                      fontSize: "12px",
                      marginTop: "7px",
                      lineHeight: "26px",
                    }}
                  >
                    <div style={{ fontSize: "12px", marginRight: "20px" }}>
                      {timeAgo && `Last updates: ${timeAgo}`}
                    </div>
                  </div>
                  <div>
                    <Radio.Group
                      value={selectedFilter}
                      onChange={(e) => setSelectedFilter(e.target.value)}
                      className="trade-history-radio-btn"
                      style={{
                        marginRight: "20px",
                        background: "#f9f9f9",
                        padding: "2px",
                        borderRadius: "10px",
                        fontWeight: "600",
                        color: "#F9F9F9",
                        border: "1px solid #D3D3DB",
                      }}
                    >
                      <Radio.Button value="openTrades">
                        Open Trades ({tradeCounts.openTrades})
                      </Radio.Button>
                      <Radio.Button value="openOrders">
                        Open Orders ({tradeCounts.openOrders})
                      </Radio.Button>
                      <Radio.Button value="history">
                        History ({tradeCounts.history})
                      </Radio.Button>
                    </Radio.Group>
                  </div>
                  <div>
                    {!dropdownVisible && (
                      <Button
                        onClick={handleButtonClick}
                        style={{
                          color: "#0062FF",
                          borderColor: "#0062FF",
                          marginRight: "20px",
                          fontWeight: "600",
                          fontSize: "15px",
                          height: "40px",
                        }}
                      >
                        {calender} Date Range
                      </Button>
                    )}
                    {dateVisible && dropdownVisible && (
                      <RangePicker
                        value={selectedDates}
                        presets={rangePresets}
                        onClick={() => setDropdownVisible(true)}
                        onChange={onRangeChange}
                        onOpenChange={handleDropdownChange} // Handle dropdown open/close
                        open={dropdownVisible} // Control the dropdown visibility
                        className="date-range-picker"
                        style={{
                          marginRight: "20px",
                          fontWeight: "600",
                          fontSize: "15px",
                        }}
                      />
                    )}
                  </div>
                  <Button
                    type="link"
                    onClick={showModal}
                    style={{
                      // border: "1px solid #0062FF",
                      color: "#0062FF",
                      fontSize: "15px",
                      height: "40px",
                      background: "#E6F0FF",
                    }}
                  >
                    {filter}
                    Filter
                  </Button>
                </div>
              </div>
            }
          >
            <div className="table-responsive">
              <ResponsivePagination setPageSize={setPageSize} />
              <ResponsiveScroll setScroll={setScroll} />
              <Table
                className={`custom-ant-table ${
                  data.length === 0 ? "layoutAuto" : "layoutFix"
                }`}
                columns={visibleColumns
                  .map((col) => ({
                    ...col,
                    width: col.width,
                  }))
                  .reduce((acc, col, index) => {
                    acc.push(col); // Add the original column
                    acc.push({
                      title: "", // Add spacer column
                      key: `spacer_${index}`,
                      width: 10,
                    });
                    return acc;
                  }, [])}
                dataSource={filteredData.map((row) => ({
                  ...row,
                  spacer: "", // Add an empty value for the spacer column
                }))}
                rowKey="ticket"
                pagination={{ pageSize }} // Set dynamic pageSize
                scroll={scroll} // Enable horizontal and vertical scroll
                expandable={{
                  expandedRowRender: (record) =>
                    childData.some(
                      (item) => record.ticket === item.parentTicket
                    ) ? (
                      <div className="nested-table">
                        <Table
                          columns={visibleColumns}
                          dataSource={childData.filter(
                            (item) => record.ticket === item.parentTicket
                          )}
                          pagination={false}
                          rowKey="tradeID"
                          rowClassName={() => "nested-row"}
                        />
                      </div>
                    ) : null,
                  rowExpandable: (record) =>
                    childData.some(
                      (item) => record.ticket === item.parentTicket
                    ),
                }}
              />
            </div>
          </Card>
          {/* </Col> */}
        </Row>
      </div>
      <Modal
        title={
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              margin: "10px 0",
              color: "#0062FF",
            }}
          >
            Select Columns
          </div>
        }
        visible={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        width={600}
        style={{ padding: "10px" }}
        bodyStyle={{ padding: "14px", backgroundColor: "#f7faff" }}
        okText="Select"
        okButtonProps={{
          style: {
            backgroundColor: "#0062FF",
            color: "white",
            fontWeight: "bold",
          },
        }}
      >
        <div
          style={{
            marginBottom: "10px",
            fontWeight: "Bold",
            fontSize: "14px",
            color: "#0062FF",
          }}
        >
          <Button
            type="link"
            onClick={selectAll}
            style={{
              fontWeight: isAllSelected ? "bold" : "normal",
              color: "#0062FF",
            }}
          >
            ALL
          </Button>
          <Button
            type="link"
            onClick={selectNone}
            style={{
              fontWeight: isNoneSelected ? "bold" : "normal",
              color: "#0062FF",
            }}
          >
            NONE
          </Button>
          <Button
            type="link"
            onClick={selectDefault}
            style={{
              fontWeight: isDefaultSelected ? "bold" : "normal",
              color: "#0062FF",
            }}
          >
            DEFAULT
          </Button>
        </div>
        <p style={{ marginBottom: "27px", color: "gray", marginTop: "-5px" }}>
          Select the columns you want to display in the table
        </p>
        <Checkbox.Group
          options={columnsOption.map((col) => ({
            label: col.title,
            value: col.key,
          }))}
          value={selectedColumns} // Sync the checked state with the selected columns
          onChange={onCheckboxChange}
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            columnGap: "2px",
            marginBottom: "30px",
            rowGap: "5px",
          }}
        />
      </Modal>
    </div>
  );
};

export default TradesHistory;
