import ReactApexChart from "react-apexcharts";
import { Row, Col, Typography, Card } from "antd";
import { useEffect, useState } from "react";

function NetPaLCumulative({ data }) {
  const { Title } = Typography;
  const [dailyStats, setDailyStats] = useState([]);
  useEffect(() => {
    console.log(data);
    setDailyStats(data);
    const getCumulativePaL = calculateNetPaLCumulative(data);
    console.log("getCumulativePaL", getCumulativePaL);

    setDailyStats(getCumulativePaL);
  }, [data]);

  function calculateNetPaLCumulative(data) {
    let cumulativePal = 0;

    return data.map((entry) => {
      cumulativePal += parseFloat(entry.pal); // Accumulate the pal value

      return {
        date: entry.date,
        netPaLCumulative: cumulativePal.toFixed(2), // Format to 2 decimal places
      };
    });
  }

  const cumulativeSeries = [
    {
      name: "Net CUMULATIVE P&L",
      data: dailyStats?.map((item) => +item.netPaLCumulative) || [],
      color: "#0062ff",
      areaStyle: {
        opacity: 0.4, // Add opacity for the area fill
      },
    },
  ];

  const cumulativeOptions = {
    chart: {
      type: "line", // Change to line chart
      height: "auto",
      toolbar: {
        show: true,
      },
      color: "#0062ff",
      areaStyle: {
        opacity: 0.4, // Add opacity for the area fill
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "40%",
        borderRadius: 0,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth", // Smooth the line curve
      width: 2,
    },
    // fill: {
    //   type: "gradient",
    //   gradient: {
    //     shadeIntensity: 1,
    //     opacityFrom: 0.7,
    //     opacityTo: 0.9,
    //     stops: [0, 90, 100],
    //   },
    // },
    colors: ["#0062ff"], // Default color of the line
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories:
        dailyStats?.map((item) => new Date(item.date).toLocaleDateString()) ||
        [],
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#000"],
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: ["#000"],
        },
      },
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
    // fill: {
    //   type: "solid",
    //   colors:
    //     dailyStats?.map((item) =>
    //       item.netPaLCumulative >= 0 ? "#00FF00" : "#FF0000"
    //     ) || [], // Green for positive, red for negative
    // },
  };
  return (
    <>
      {/* <div>
        <RangePicker presets={rangePresets} onChange={onRangeChange} />
      </div> */}
      <br />
      <div id="chart">
        <Row gutter={[24, 0]}>
          <Col xs={24}>
            <Card bordered={false} className="criclebox h-full">
              <Title style={{ margin: "5px", color: "#1A1C21" }} level={4}>
                NET CUMULATIVE P&L
              </Title>
              <ReactApexChart
                className="bar-chart1"
                options={cumulativeOptions}
                series={cumulativeSeries}
                type="line"
                height={300}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NetPaLCumulative;
