import { Col, Row } from "antd";
import React, { useEffect, useState } from "react";
import { getDailyStatsData } from "../../../api/api";
import OverviewTable from "./overviewTable";
import NetPaL from "../netPaL";
import NetPaLCumulative from "../netPalCumulative";

const TradingOverview = ({ dateRange }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    const loginID = localStorage.getItem("loginID");
    console.log("loginID", loginID);
    console.log("dateRange", dateRange);

    getDailyStatsData(loginID, dateRange.fromDate, dateRange.toDate)
      .then((res) => {
        console.log(res.data);

        setData(res.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [dateRange]);

  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col xs={24} md={12}>
          <br />
          <OverviewTable data={data} />
        </Col>
        <Col xs={24} md={12} >
          <Row gutter={[24, 0]}>
            <Col xs={24}>
              <NetPaL data={data} />
            </Col>
            <Col xs={24}>
              <NetPaLCumulative data={data} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default TradingOverview;
