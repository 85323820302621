import React, { useEffect, useState } from "react";
import { Col, Modal, Row, Select } from "antd";
import { Button, Form, Input } from "antd";
import { addAccount, editAccount, getBrokerList } from "../../api/api";
import useDynamicNotification from "../notification";

const AddAccountModal = ({ isModalOpen, setIsModalOpen, editData }) => {
  const { openNotification, contextHolder } = useDynamicNotification();
  const [brokerList, setBrokerList] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState("mt4");
  const [filteredBrokers, setFilteredBrokers] = useState([]);
  const [form] = Form.useForm(); // Create form reference

  useEffect(() => {
    getBrokerList()
      .then((res) => {
        if (res.status === 200) {
          setBrokerList(res.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setFilteredBrokers(
      brokerList.filter(
        (broker) => broker.type.toLowerCase() === selectedAccountType
      )
    );
  }, [selectedAccountType, brokerList]);

  useEffect(() => {
    if (editData.length > 0) {
      console.log("Populating form with editData:", editData);
      form.setFieldsValue({
        type: editData?.[0].type || "mt4", // fallback to default if undefined
        server: editData?.[0].server || "",
        login: editData?.[0].login || "",
        password: editData?.[0].password || "",
      });
      setSelectedAccountType(editData?.[0].type || "mt4"); // to update the selected account type for filtering brokers
    }
  }, [editData, form]);

  const onFinish = async (values) => {
    console.log("Success:", values);
    const userID = localStorage.getItem("userID");
    values.userID = userID;
    if (editData.length) {
      const res = await editAccount(userID, values);
      if (res.data.message === "Account edit successfully!") {
        openNotification("success", "Account edit successfully!");
        handleCancel();
      } else if (res.data.message === "Account already exist!") {
        openNotification("error", res.data.message || "");
      }
    } else {
      const res = await addAccount(values);
      if (res.data.message === "Account added successfully!") {
        openNotification("success", "Account added successfully!");
        handleCancel();
      } else if (res.data.message === "Account already exist!") {
        openNotification("error", res.data.message || "");
      }
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const handleOk = () => {
    setIsModalOpen((prevState) => !prevState);
  };

  const handleCancel = () => {
    setIsModalOpen((prevState) => !prevState);
    form.resetFields(); // Reset form on modal close
  };

  const handleAccountTypeChange = (value) => {
    setSelectedAccountType(value);
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
              margin: "10px 0",
            }}
          >
            {editData
              ? "Update Metatrader Account Details"
              : "Enter Metatrader Account Details"}
          </div>
        }
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        closable={false} // This removes the cross button
        style={{ borderRadius: "8px" }}
        bodyStyle={{ padding: "10px 24px", backgroundColor: "#f9f9f9" }}
      >
        <div style={{ width: "100%" }}>
          <Form
            form={form} // Attach the form instance
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            layout="vertical"
          >
            <Form.Item
              label={
                <span style={{ color: "#333", fontWeight: "500" }}>
                  Select Metatrader Type
                </span>
              }
              name="type"
              initialValue="mt4"
            >
              <Select
                style={{ width: "100%" }}
                onChange={handleAccountTypeChange}
                disabled={editData.length}
              >
                <Select.Option value="mt4">Metatrader 4</Select.Option>
                <Select.Option value="mt5">Metatrader 5</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span style={{ color: "#333", fontWeight: "500" }}>Server</span>
              }
              name="server"
            >
              <Select
                style={{ width: "100%" }}
                showSearch
                placeholder="Select a server"
                optionFilterProp="children"
                filterOption={(input, option) =>
                  option.children.toLowerCase().includes(input.toLowerCase())
                }
                disabled={editData.length}
              >
                {filteredBrokers.map((broker) => (
                  <Select.Option key={broker.name} value={broker.name}>
                    {broker.name}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label={
                <span style={{ color: "#333", fontWeight: "500" }}>Login</span>
              }
              name="login"
              rules={[
                { required: true, message: "Please input your server login!" },
              ]}
            >
              <Input
                placeholder="Enter your login"
                disabled={editData.length}
              />
            </Form.Item>

            <Form.Item
              label={
                <span style={{ color: "#333", fontWeight: "500" }}>
                  Password
                </span>
              }
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your server password!",
                },
              ]}
            >
              <Input placeholder="Enter your password" />
            </Form.Item>
            <Form.Item>
              <Row gutter={[24, 0]}>
                <Col xs={12}>
                  <Button onClick={handleCancel} style={{ width: "100%" }}>
                    Cancel
                  </Button>
                </Col>
                <Col xs={12}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ marginRight: "10px", width: "100%" }}
                  >
                    {editData.length ? "Update Account" : "Add Account"}
                  </Button>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </>
  );
};

export default AddAccountModal;
