import { Card, Col, Row, Typography } from "antd";
import { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";

function NetPaL({ data }) {
  const { Title } = Typography;
  const [dailyStats, setDailyStatData] = useState([]);

  useEffect(() => {
    setDailyStatData(data);
  }, [data]);

  const netSeries = [
    {
      name: "Sales",
      data: dailyStats?.map((item) => +item.pal) || [],
      color: "#fff",
    },
  ];

  const netOptions = {
    chart: {
      type: "bar",
      // width: "100%",
      height: "auto",

      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "8",
        borderRadius: "2",
        colors: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: "#0062ff", // Red for negative values
            },
            {
              from: 0.01,
              to: Infinity,
              color: "#0062ff", // Green for positive values
            },
          ],
        },
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 1,
      colors: ["transparent"],
    },
    grid: {
      show: true,
      borderColor: "#ccc",
      strokeDashArray: 2,
    },
    xaxis: {
      categories:
        dailyStats?.map((item) => new Date(item.date).toLocaleDateString()) ||
        [],
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
          ],
        },
      },
    },
    yaxis: {
      labels: {
        show: true,
        align: "right",
        minWidth: 0,
        maxWidth: 160,
        style: {
          colors: [
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
            "#000",
          ],
        },
      },
    },

    tooltip: {
      y: {
        formatter: function (val) {
          return "$ " + val + " thousands";
        },
      },
    },
  };

  return (
    <>
      {/* <div>
        <RangePicker presets={rangePresets} onChange={onRangeChange} />
      </div> */}
      <br />
      <div id="chart">
        <Row gutter={[24, 0]}>
          <Col xs={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Title style={{ margin: "5px", color:"#1A1C21" }} level={4}>
                NET P&L
              </Title>
              <ReactApexChart
                className="bar-chart1"
                options={netOptions}
                series={netSeries}
                type="bar"
                height={300}
              />
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default NetPaL;
