export const overview = [
    <svg width="18px" height="18px" className="overviewIcon" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="🔍-System-Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g id="ic_fluent_data_pie_24_filled" fill="#858b9d" fill-rule="nonzero">
                <path d="M10.25,4.25 C10.6642136,4.25 11,4.58578644 11,5 L11,13 L19,13 C19.3796958,13 19.693491,13.2821535 19.7431534,13.648229 L19.75,13.75 C19.75,18.7205627 15.7205627,22.25 10.75,22.25 C5.77943725,22.25 1.75,18.2205627 1.75,13.25 C1.75,8.27943725 5.27943725,4.25 10.25,4.25 Z M13.25,1.75 C18.2205627,1.75 22.25,5.77943725 22.25,10.75 C22.25,11.1642136 21.9142136,11.5 21.5,11.5 L21.5,11.5 L13.25,11.5 C12.8357864,11.5 12.5,11.1642136 12.5,10.75 L12.5,10.75 L12.5,2.5 C12.5,2.08578644 12.8357864,1.75 13.25,1.75 Z" id="🎨-Color">
                </path>
            </g>
        </g>
    </svg>
]
export const timeReport = [
    <svg className="timeReport" width="17" height="17" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg"><path d="m16 0c8.836556 0 16 7.163444 16 16s-7.163444 16-16 16-16-7.163444-16-16 7.163444-16 16-16zm1 9h-2l.000151 6.9280812-.0023391.0030391.0020555.4829607 6.8285597 6.8285597 1.4142136-1.4142136-6.2420846-6.2432927z" fill="#858b9d" fill-rule="evenodd" /></svg>
]
export const risk = [
    <svg id="Layer_2" className="health" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 1069.6 1136.56">
        <defs>
            <style>{`
      .cls-331 {
        fill: #858b9d;
        stroke-width: 0px;
      }
    `}</style>
        </defs>
        <g id="Layer_5" data-name="Layer 5">
            <path class="cls-331" d="M968.16,486.7c-.45,15.83-1.33,31.19-2.64,46.57-48.32-26.36-104.11-41.72-163.42-41.72-187.12,0-338.68,151.99-338.68,339.11,0,108.06,50.53,204.71,129.59,266.2-8.34,4.83-17.57,9.22-26.36,13.62-19.33,9.67-39.96,16.69-61.07,22.84-25.03,7.89-48.75,0-72.03-9.22-67.22-25.91-126.08-65.01-180.56-111.15-85.22-72.03-151.11-158.57-195.46-261.36-30.76-71.62-51.39-146.28-54.03-224.03C-.43,425.66.88,323.73,0,221.38c0-14.93,6.16-21.51,21.96-26.34,71.17-20.23,141.01-43.5,209.54-71.62,78.63-32.5,154.63-70.27,228.41-112.89,5.73-3.52,11.88-7.04,18.02-10.53h13.17c141.46,83.46,291.26,148.47,449.4,193.28,25.03,7.01,28.55,11.41,28.55,37.34-.45,85.22,1.31,170.86-.88,256.08Z" />
            <path class="cls-331" d="M955.4,611.85c-43.07-30.76-95.77-48.32-152.89-48.32-147.59,0-267.08,119.49-267.08,267.08,0,95.32,49.65,178.78,124.77,225.79,41.29,26.36,90.04,41.29,142.31,41.29,147.61,0,267.08-119.49,267.08-267.08,0-90.49-45.24-170.88-114.19-218.76ZM802.51,666.87c15.96,0,25.82,8.45,25.82,28.61,0,17.37-5.19,61.94-11.28,127.62l-6.09,65.2h-16.9l-6.09-65.2c-6.09-65.68-11.24-110.25-11.24-127.62,0-20.16,9.82-28.61,25.78-28.61ZM802.51,994.35c-16.43,0-29.56-13.6-29.56-29.56s13.13-30.03,29.56-30.03,29.56,14.07,29.56,30.03-13.13,29.56-29.56,29.56Z" />
        </g>
    </svg>
]
export const equity = [
    <svg className="equity" fill="#858b9d" height="18px" width="18px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 462.587 462.587" xmlSpace="preserve">
        <g id="XMLID_12_">
            <g>
                <path d="M457.012,206.595c-1.509-1.656-3.647-2.608-5.888-2.608h-15.69c0,0-54.307-103.321-57.115-108.681
           c-3.012-5.747-7.57-10.778-14.387-10.778c-33.177,0-64.138-7.079-87.179-19.935c-11.496-6.414-20.164-13.917-25.46-21.803V20
           c0-11.046-8.954-20-20-20c-11.046,0-20,8.954-20,20v22.79c-5.296,7.886-13.964,15.389-25.46,21.803
           c-23.042,12.855-54.003,19.935-87.18,19.935c-6.817,0-10.923,4.181-14.387,10.778c-8.099,15.424-57.114,108.681-57.114,108.681
           H11.463c-2.242,0-4.381,0.945-5.891,2.602c-1.51,1.658-2.25,3.874-2.045,6.107c4.394,47.706,44.521,85.063,93.374,85.063
           s88.98-37.357,93.374-85.063c0.205-2.231-0.539-4.445-2.048-6.101c-1.509-1.656-3.647-2.608-5.888-2.608h-15.69l-47.389-90.282
           c36.922-2.961,69.151-13.791,92.034-29.701v328.583h-90.649c-13.807,0-25,11.193-25,25s11.193,25,25,25h217.453
           c13.807,0,25-11.193,25-25s-11.193-25-25-25h-86.804V84.004c22.883,15.91,55.112,26.741,92.033,29.701l-47.389,90.282h-15.69
           c-2.242,0-4.381,0.945-5.891,2.602c-1.51,1.658-2.25,3.874-2.044,6.107c4.393,47.706,44.521,85.063,93.374,85.063
           c48.853,0,88.98-37.357,93.374-85.063C459.266,210.465,458.521,208.252,457.012,206.595z M132.767,203.987H61.034l35.866-68.33
           L132.767,203.987z M329.819,203.987l35.867-68.331l35.867,68.331H329.819z"/>
            </g>
        </g>
    </svg>
]
export const goals = [
    <svg
        className="goals"
        fill="#858b9d"
        width="18px"
        height="18px"
        viewBox="0 0 24 24"
        id="goal"
        data-name="Flat Line"
        xmlns="http://www.w3.org/2000/svg"
    >
        <polygon
            id="secondary"
            points="15 6 15 9 18 9 21 6 18 6 18 3 15 6"
            style={{ fill: "#858b9d", strokeWidth: 2 }}
        ></polygon>
        <path
            id="primary"
            d="M15,9l-2.5,2.5M15,6V9h3l3-3H18V3Z"
            style={{
                fill: "none",
                stroke: "#858b9d",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
            }}
        ></path>
        <path
            id="primary-2"
            data-name="primary"
            d="M12.33,3H12a9,9,0,1,0,9,9c0-.11,0-.22,0-.33"
            style={{
                fill: "none",
                stroke: "#858b9d",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
            }}
        ></path>
        <path
            id="primary-3"
            data-name="primary"
            d="M16.9,13A5,5,0,1,1,11,7.1"
            style={{
                fill: "none",
                stroke: "#858b9d",
                strokeLinecap: "round",
                strokeLinejoin: "round",
                strokeWidth: 2,
            }}
        ></path>
    </svg>
]
export const volume = [
    <svg className="volume" width="18px" height="18px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.00299 11.7155C2.04033 9.87326 2.059 8.95215 2.67093 8.16363C2.78262 8.0197 2.9465 7.8487 3.08385 7.73274C3.83639 7.09741 4.82995 7.09741 6.81706 7.09741C7.527 7.09741 7.88197 7.09741 8.22035 7.00452C8.29067 6.98522 8.36024 6.96296 8.4289 6.93781C8.75936 6.81674 9.05574 6.60837 9.64851 6.19161C11.9872 4.54738 13.1565 3.72527 14.138 4.08241C14.3261 4.15088 14.5083 4.24972 14.671 4.37162C15.5194 5.00744 15.5839 6.48675 15.7128 9.44537C15.7606 10.5409 15.7931 11.4785 15.7931 12C15.7931 12.5215 15.7606 13.4591 15.7128 14.5546C15.5839 17.5132 15.5194 18.9926 14.671 19.6284C14.5083 19.7503 14.3261 19.8491 14.138 19.9176C13.1565 20.2747 11.9872 19.4526 9.64851 17.8084C9.05574 17.3916 8.75936 17.1833 8.4289 17.0622C8.36024 17.037 8.29067 17.0148 8.22035 16.9955C7.88197 16.9026 7.527 16.9026 6.81706 16.9026C4.82995 16.9026 3.83639 16.9026 3.08385 16.2673C2.9465 16.1513 2.78262 15.9803 2.67093 15.8364C2.059 15.0478 2.04033 14.1267 2.00299 12.2845C2.00103 12.1878 2 12.0928 2 12C2 11.9072 2.00103 11.8122 2.00299 11.7155Z" fill="#858b9d" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4895 5.55219C19.7821 5.29218 20.217 5.33434 20.4608 5.64635L19.931 6.11713C20.4608 5.64635 20.4606 5.64602 20.4608 5.64635L20.4619 5.6477L20.4631 5.64921L20.4658 5.65275L20.4727 5.66184C20.4779 5.6688 20.4844 5.67756 20.4921 5.68814C20.5075 5.70929 20.5275 5.73772 20.5515 5.77358C20.5995 5.84529 20.6635 5.94667 20.7379 6.07889C20.8868 6.34345 21.077 6.73092 21.2644 7.25038C21.6397 8.29107 22 9.85136 22 12.0002C22 14.1491 21.6397 15.7094 21.2644 16.7501C21.077 17.2695 20.8868 17.657 20.7379 17.9216C20.6635 18.0538 20.5995 18.1552 20.5515 18.2269C20.5275 18.2627 20.5075 18.2912 20.4921 18.3123C20.4844 18.3229 20.4779 18.3317 20.4727 18.3386L20.4658 18.3477L20.4631 18.3513L20.4619 18.3528C20.4616 18.3531 20.4608 18.3541 19.931 17.8833L20.4608 18.3541C20.217 18.6661 19.7821 18.7083 19.4895 18.4483C19.1983 18.1895 19.1578 17.729 19.3977 17.417C19.3983 17.4163 19.3994 17.4148 19.4009 17.4127C19.4058 17.406 19.4154 17.3925 19.4291 17.372C19.4565 17.3311 19.5003 17.2625 19.5552 17.1649C19.6649 16.9698 19.8195 16.6587 19.977 16.2221C20.2913 15.3508 20.6207 13.9695 20.6207 12.0002C20.6207 10.0309 20.2913 8.64968 19.977 7.77836C19.8195 7.34181 19.6649 7.03066 19.5552 6.8356C19.5003 6.73802 19.4565 6.66934 19.4291 6.62845C19.4154 6.608 19.4058 6.59449 19.4009 6.58778C19.3994 6.58561 19.3983 6.58416 19.3977 6.5834C19.3977 6.5834 19.3977 6.58341 19.3977 6.5834" fill="#858b9d" />
        <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7571 8.41595C18.0901 8.21871 18.51 8.34663 18.6949 8.70166L18.0921 9.0588C18.6949 8.70166 18.6948 8.70134 18.6949 8.70166L18.6956 8.70295L18.6963 8.70432L18.6978 8.7073L18.7014 8.71428L18.7102 8.73227C18.7169 8.74607 18.7251 8.76348 18.7345 8.78457C18.7533 8.82676 18.7772 8.88363 18.8042 8.95574C18.8584 9.10004 18.9251 9.3049 18.99 9.57476C19.1199 10.115 19.2415 10.9119 19.2415 12.0003C19.2415 13.0888 19.1199 13.8857 18.99 14.4259C18.9251 14.6958 18.8584 14.9007 18.8042 15.045C18.7772 15.1171 18.7533 15.1739 18.7345 15.2161C18.7251 15.2372 18.7169 15.2546 18.7102 15.2684L18.7014 15.2864L18.6978 15.2934L18.6963 15.2964L18.6956 15.2978C18.6954 15.2981 18.6949 15.299 18.0921 14.9419L18.6949 15.299C18.51 15.6541 18.0901 15.782 17.7571 15.5847C17.427 15.3892 17.3063 14.9474 17.4846 14.5938L17.4892 14.5838C17.4955 14.5697 17.5075 14.5415 17.5236 14.4987C17.5557 14.4132 17.6039 14.2688 17.6539 14.0606C17.7539 13.6448 17.8622 12.9709 17.8622 12.0003C17.8622 11.0298 17.7539 10.3559 17.6539 9.94007C17.6039 9.73193 17.5557 9.58748 17.5236 9.50197C17.5075 9.45918 17.4955 9.43102 17.4892 9.41691L17.4846 9.40687C17.3063 9.05332 17.427 8.61152 17.7571 8.41595Z" fill="#858b9d" />
    </svg>
]
export const health = [
    <svg id="Layer_2" className="health" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 527.83 569.05">
        <defs>
            <style>
                {` .cls-31 {
                    fill: #858b9d;
                stroke: #000;
                stroke-miterlimit: 10;
                stroke-width: 10px;
      }`}
            </style>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <path class="cls-31" d="M455.91,316.75v86.15c0,89.01-72.15,161.16-161.16,161.16s-161.16-72.15-161.16-161.16v-18.95C59.61,372.42,5.04,308.72,5,233.84v-121.73c.01-23.92,12.09-46.22,32.13-59.28,1.74-28.01,25.86-49.31,53.87-47.58,28.01,1.74,49.31,25.86,47.58,53.87-1.74,28.01-25.86,49.31-53.87,47.58-9.91-.62-19.42-4.12-27.37-10.08-3.66,4.33-5.68,9.82-5.68,15.49v121.68c0,58.14,47.13,105.26,105.26,105.26s105.26-47.13,105.26-105.26v-121.68c0-5.05-1.59-9.97-4.55-14.06-23.3,15.69-54.91,9.53-70.6-13.77-15.69-23.3-9.53-54.91,13.77-70.6,23.3-15.69,54.91-9.53,70.6,13.77,5.53,8.21,8.54,17.85,8.67,27.74,18.08,13.34,28.75,34.46,28.76,56.93v121.68c-.04,74.88-54.61,138.57-128.59,150.11v18.95c.03,63.23,51.3,114.46,114.53,114.44,63.19-.03,114.41-51.25,114.44-114.44v-86.11c-48.16-12.88-76.76-62.37-63.88-110.53s62.37-76.76,110.53-63.88c48.16,12.88,76.76,62.37,63.88,110.53-8.34,31.18-32.7,55.54-63.88,63.88h.04ZM476.12,229.59c0-24.07-19.51-43.58-43.58-43.58-24.07,0-43.58,19.51-43.58,43.58s19.51,43.58,43.58,43.58h0c24.07-.02,43.57-19.51,43.62-43.58h-.04Z" />
        </g>
    </svg>
]
export const calender = [
    <svg className="calender" id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18px" height="18px" viewBox="0 0 50 47.17">
        <defs>
            <style>
                {`.cls-21 {
        fill: #9a9fae;
        stroke-width: 0px;
      }`}
            </style>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <g>
                <path class="cls-21" d="M50,15.93v.42H0v-.42C0,8.71,5.86,2.85,13.1,2.85h.91V0h2.74v2.85h16.69V0h2.74v2.85h.74c7.22,0,13.08,5.86,13.08,13.08Z" />
                <path class="cls-21" d="M0,19.28v20.09c0,4.31,3.49,7.8,7.8,7.8h34.4c4.31,0,7.8-3.49,7.8-7.8v-20.09H0ZM18.03,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM27.79,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM37.55,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47Z" />
            </g>
        </g>
    </svg>
]