import React from "react";
import { Tabs } from "antd";
import {
  calender,
  equity,
  goals,
  health,
  overview,
  risk,
  timeReport,
  volume,
} from "../Reports/svg-icons";
import TradingOverview from "../Reports/overview/overview";

const TabsComponent = ({ dateRange }) => {
  const onChange = (key) => {
    console.log(key);
  };
  console.log('TabsComponent');
  
  const items = [
    {
      key: "1",
      label: (
        <>
          {overview} <div>Overview</div>
        </>
      ),
      children: (
        <>
          <TradingOverview dateRange={dateRange} />
        </>
      ),
    },
    {
      key: "2",
      label: <>{timeReport} Time Report</>,
      disabled: true,
    },
    {
      key: "3",
      label: <>{risk} Risk Report</>,
      disabled: true,
    },
    {
      key: "4",
      label: <>{equity} Equity Report</>,
      disabled: true,
    },
    {
      key: "5",
      label: <>{goals} Goal Report</>,
      disabled: true,
    },
    {
      key: "6",
      label: <>{volume} Volume Report</>,
      disabled: true,
    },
    {
      key: "7",
      label: <>{health} Health Report</>,
      disabled: true,
    },
    {
      key: "8",
      label: <>{calender} Calender Report</>,
      disabled: true,
    },
  ];
  return (
    <div className="trading-overview">
      <Tabs
        size="large"
        type="card"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
      />
    </div>
  );
};

export default TabsComponent;
