import React, { useEffect, useState } from "react";
import { Button, Card, Timeline, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";
import { MenuUnfoldOutlined } from "@ant-design/icons";

const RecentTradesCard = ({ data }) => {
  const { Title, Text } = Typography;
  const [recentTrades, setRecentTrades] = useState([]);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      setRecentTrades(data);
    }
  }, [data]);
  return (
    <div>
      {" "}
      <Card
        bordered={false}
        className="criclebox h-full"
        style={{ minWidth: "300px" }}
      >
        <div className="timeline-box">
          <Title level={5}>Recent Trades / Open Positions</Title>
          <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
            this month <span className="bnb2">20%</span>
          </Paragraph>

          <div
            className="list"
            style={{ maxHeight: "500px", overflowY: "auto", padding: "6px" }}
          >
            <Timeline
              pending="Loading..."
              className="timelinelist"
              reverse={reverse}
            >
              {recentTrades.length > 0 &&
                recentTrades.map((t, index) => (
                  <Timeline.Item color={t.color} key={index}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5}>{t.title}</Title>
                      <Title
                        style={{ marginLeft: "30px", margin: "auto" }}
                        level={5}
                      >
                        {t.symbol}
                      </Title>
                      <Title
                        style={{ marginLeft: "30px", color: t.color }}
                        level={5}
                      >
                        {t.price}
                      </Title>
                    </div>
                    <Text>{t.time}</Text>
                  </Timeline.Item>
                ))}
            </Timeline>
          </div>

          <Button
            type="primary"
            className="width-100"
            onClick={() => setReverse(!reverse)}
          >
            {<MenuUnfoldOutlined />} Trades
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default RecentTradesCard;
