import React from "react";

const EmailExpire = () => {

  return (
    <div className="verifyEmailPage">
      <>
        <h2 style={{ textAlign: "center", mt: "2rem" }}>
          This Link is Expired ...
        </h2>
        <br></br>
        <img
          src={"/images/Email-Link-Expired.jpg"}
          style={{
            textAlign: "center",
            display: "block",
            margin: "0 auto",
            width: "50%",
          }}
          alt="email expire"
        />
      </>
    </div>
  );
};

export default EmailExpire;
