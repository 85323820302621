import { useEffect } from 'react';

const ResponsivePagination = ({ setPageSize }) => {
  // Function to handle screen resize and adjust pagination
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 768) {
      setPageSize(5); // Smaller screens, show 5 rows
    } else if (screenWidth >= 768 && screenWidth < 1500) {
      setPageSize(10); // Medium screens, show 10 rows
    } else {
      setPageSize(15); // Larger screens, show 15 rows
    }
  };

  // Add a resize listener
  useEffect(() => {
    handleResize(); // Initialize page size based on current screen size
    window.addEventListener('resize', handleResize); // Adjust on resize
    return () => window.removeEventListener('resize', handleResize); // Clean up listener on component unmount
  }, []);

  return null; // This component does not render anything
};

export default ResponsivePagination;