
import axios from 'axios'

export const signupUser = async (values) => {
  try {
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/addUser`, values)
    return res
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { error: error.message || "An error occurred during sign-up." };
    }
  }
}
export const signInUser = async (email, password) => {
  try {
    console.log('signInUser', process.env.REACT_APP_SERVER_URL);
    const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/api/login`, { email, password })
    return res
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { error: error.message || "An error occurred during sign-in." };
    }
  }
}
export const verifyEmail = async (emailToken) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/verify-email/${emailToken}`)
    return res
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { error: error.message || "An error occurred during sign-in." };
    }
  }
}

export const updateEmailToken = async (email) => {
  try {
    const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/api/updateEmailToken/${email}`)
    return res
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      return { error: error.message || "An error occurred during sign-in." };
    }
  }
}
