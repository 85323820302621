import 'resize-observer-polyfill';
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/Home";
import TradesHistory from "./components/TradeHistory/tradesHistory"
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import VerifyEmail from "./pages/VerifyEmail";
import EmailExpire from "./pages/EmailExpire";
import Reports from './pages/Reports';

function App() {
  console.log('setEnvs', process.env.REACT_APP_SERVER_URL);
  console.log("-----------------------");
  
  return (
    <div className="App">
      <Switch>
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/email-expire" component={EmailExpire} />
        <ProtectedRoute exact path="/dashboard" component={Home} />
        <ProtectedRoute exact path="/performance-overview" component={Home} />
        <ProtectedRoute path="/trade-report" component={Reports} />
        <ProtectedRoute path="/trade-history" component={TradesHistory} />
        <ProtectedRoute exact path="/tables" component={Tables} />
        <ProtectedRoute exact path="/billing" component={Billing} />
        <ProtectedRoute exact path="/rtl" component={Rtl} />
        <ProtectedRoute exact path="/profile" component={Profile} />
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </div>
  );
}

export default App;
