import React from 'react';
import { notification } from 'antd';
import { InfoCircleOutlined, CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

// Dynamic Notification Component
const useDynamicNotification = () => {
    const [api, contextHolder] = notification.useNotification();

    const openNotification = (action, description = '') => {
        let config = {
            // message: message,
            description: description,
        };

        // Switch case for different actions
        switch (action) {
            case 'info':
                config = {
                    ...config,
                    icon: <InfoCircleOutlined style={{ color: '#1890ff' }} />,
                };
                api.info(config);
                break;
            case 'success':
                config = {
                    ...config,
                    icon: <CheckCircleOutlined style={{ color: '#52c41a' }} />,
                };
                api.success(config);
                break;
            case 'error':
                config = {
                    ...config,
                    icon: <CloseCircleOutlined style={{ color: '#E65964' }} />,
                };
                api.error(config);
                break;
            case 'warning':
                config = {
                    ...config,
                    icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
                };
                api.warning(config);
                break;
            default:
                api.info(config);
        }
    };

    return { openNotification, contextHolder };
};

export default useDynamicNotification; // Export the hook