import React, { useState, useEffect } from "react";
import { deleteAccount, getAccounts } from "../../api/api";
import { Dropdown, Button, List, Modal, Row, Col } from "antd";
import {
  AppstoreFilled,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AddAccountModal from "../AddAccount/addAccount";
import useDynamicNotification from "../notification";

const Accounts = () => {
  const { openNotification, contextHolder } = useDynamicNotification();
  const [accountsData, setAccountsData] = useState([]);
  const [accountsEditData, setAccountsEditData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isDelModalOpen, setIsDelModalOpen] = useState(false);
  const [triggerData, setTriggerData] = useState(false);
  const [accountID, setIsAccountID] = useState("");
  const [dropdownVisible, setDropdownVisible] = useState(false); // State for dropdown visibility

  const showDelModal = () => {
    setIsDelModalOpen(true);
  };

  const handleOk = () => {
    setIsDelModalOpen(false);
  };

  const handleCancel = () => {
    setIsDelModalOpen(false);
  };

  const showModal = () => {
    setIsModalOpen(true);
  };

  useEffect(() => {
    fetchData();
    const intervalId = setInterval(() => fetchData(), 5000);
    return () => clearInterval(intervalId);
  }, [triggerData]);

  const fetchData = () => {
    const userID = localStorage.getItem("userID");
    const loginID = localStorage.getItem("loginID");
    if (userID) {
      getAccounts(userID)
        .then((res) => {
          if (Array.isArray(res?.data)) {
            if (!loginID) {
              localStorage.setItem("loginID", res?.data[0]?.login);
            }
            localStorage.setItem("accountBalance", res?.data[0]?.balance);
            localStorage.setItem("lastUpdates", res?.data[0]?.lastUpdates);
            setAccountsData(res?.data);
          } else setAccountsData([]);
        })
        .catch((err) => {
          console.error(err.message || err);
          setAccountsData([]);
        });
    }
  };

  const menu = (
    <div
      style={{
        background: "white",
        width: "240px",
        boxShadow: "0 0 5px gray",
        borderRadius: "5px",
      }}
    >
      <List
        className="header-notifications-dropdown"
        itemLayout="horizontal"
        dataSource={accountsData}
        renderItem={(item, i) => (
          <List.Item
            className="accounts-dropdown-item"
            style={{ margin: "0 0px", padding: "8px" }}
            key={i}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div
                style={{ cursor: "pointer", marginLeft: "5px" }}
                onClick={() => {
                  localStorage.setItem("loginID", item.login);
                  setDropdownVisible(false); // Close dropdown
                }}
              >
                <span>
                  <AppstoreFilled />
                </span>
                <span style={{ marginLeft: "5px", fontWeight: "bold" }}>
                  {item.login}
                </span>
              </div>
              <div>
                <span style={{ marginRight: "20px" }}>
                  <EditOutlined
                    style={{ color: "gray", fontSize: "15px" }}
                    onClick={() => {
                      handleEdit(item?.accountID);
                    }}
                  />
                </span>
                <span style={{ marginRight: "10px" }}>
                  <DeleteOutlined
                    style={{ color: "red", fontSize: "15px" }}
                    onClick={() => {
                      setIsAccountID(item?.accountID);
                      showDelModal(true);
                      setIsModalOpen(false);
                    }}
                  />
                </span>
              </div>
            </div>
          </List.Item>
        )}
      />
      {/* Add Account Button at the bottom */}
      <div
        style={{
          textAlign: "center",
          padding: "10px",
          borderTop: "1px solid #bbbbbb",
          fontWeight: "bold",
          color: "#0062ff",
          cursor: accountsData.length < 1 ? "pointer" : "no-drop",
        }}
      >
        <div
          onClick={() => {
            if (accountsData.length < 1) {
              showModal();
            }
            setAccountsEditData([]);
          }}
        >
          Add Account
        </div>
      </div>
    </div>
  );

  const handleEdit = (accountID) => {
    const editData = accountsData.filter(
      (account) => accountID === account.accountID
    );
    setAccountsEditData(editData);
    showModal();
  };

  const handleDelete = async () => {
    const userID = localStorage.getItem("userID");
    if (accountID) {
      const deleteData = accountsData.filter(
        (account) => accountID === account.accountID
      );
      deleteAccount(userID, deleteData).then((res) => {
        if (res.status === 200) {
          openNotification("success", "Account deleted successfully!");
          setIsDelModalOpen(false);
        } else if (res.status === 429) {
          openNotification("error", "Conflict has occurred, Try Again!");
          setIsDelModalOpen(false);
        } else {
          openNotification(
            "error",
            "Internal server error has occurred, Try Again!"
          );
          setIsDelModalOpen(false);
        }
      });
    }
  };

  return (
    <>
      {contextHolder}
      <div>
        <Dropdown
          overlay={menu}
          trigger={["hover"]}
          visible={dropdownVisible} // Control dropdown visibility
          onVisibleChange={(visible) => setDropdownVisible(visible)} // Track visibility changes
          placement="topRight"
          arrow
          overlayClassName={
            dropdownVisible ? "dropdown-fade-enter" : "dropdown-fade-leave"
          }
        >
          <Button
            onClick={() => {
              setTriggerData(!triggerData);
            }}
          >
            Accounts
          </Button>
        </Dropdown>
        <AddAccountModal
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          editData={accountsEditData}
        />
      </div>
      <Modal
        title={
          <div
            style={{
              fontSize: "22px",
              fontWeight: "bold",
              textAlign: "center",
              margin: "10px 0",
            }}
          >
            Confirm Account Deletion
          </div>
        }
        open={isDelModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="Delete"
        footer={null}
        okButtonProps={{ danger: true }}
        closable={false} // This removes the cross icon
        bodyStyle={{ padding: "24px", backgroundColor: "#f9f9f9" }}
      >
        <div style={{ textAlign: "center", padding: "10px" }}>
          <p style={{ fontSize: "16px" }}>
            Are you sure you want to delete your MetaTrader account? This action
            will permanently remove all associated data and settings.
          </p>
        </div>
        <Row gutter={[24, 0]}>
          <Col xs={12}>
            <Button onClick={handleCancel} style={{ width: "100%" }}>
              Cancel
            </Button>
          </Col>
          <Col xs={12}>
            <Button
              type="primary"
              style={{
                marginRight: "10px",
                width: "100%",
                background: "orangered",
              }}
              onClick={handleDelete}
            >
              Delete
            </Button>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default Accounts;
