import {
  AlertOutlined,
  InfoCircleOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import { Badge, Dropdown, List } from "antd";
import React, { useEffect, useState } from "react";
import { getNotifications, updateNotificationStatus } from "../../api/api";
import { formatTime } from "../../utils/utils";
const bell = [
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M10 2C6.68632 2 4.00003 4.68629 4.00003 8V11.5858L3.29292 12.2929C3.00692 12.5789 2.92137 13.009 3.07615 13.3827C3.23093 13.7564 3.59557 14 4.00003 14H16C16.4045 14 16.7691 13.7564 16.9239 13.3827C17.0787 13.009 16.9931 12.5789 16.7071 12.2929L16 11.5858V8C16 4.68629 13.3137 2 10 2Z"
      fill="#111827"
    ></path>
    <path
      d="M10 18C8.34315 18 7 16.6569 7 15H13C13 16.6569 11.6569 18 10 18Z"
      fill="#111827"
    ></path>
  </svg>,
];

const Notification = () => {
  const [notificationData, setNotificationData] = useState([]);
  const [notificationAllList, setNotificationAllList] = useState([]);
  const [badgeCount, setBadgeCount] = useState([]);
  const [unReadList, setUnReadList] = useState(true);

  useEffect(() => {
    fetchData();

    const intervalID = setInterval(() => {
      fetchData();
    }, 10000);
    return () => clearInterval(intervalID);
  }, []);

  const fetchData = async () => {
    const userID = localStorage.getItem("userID");

    getNotifications(userID)
      .then((res) => {
        if (Array.isArray(res.data.data)) {
          const sortedData = res.data.data.sort(
            (a, b) => new Date(b.time) - new Date(a.time)
          );

          setNotificationData(sortedData);
          setNotificationAllList(sortedData);
          setBadgeCount(
            res.data.data.filter((item) => item.status === "unread")
          );
        }
      })
      .catch((err) => {
        setNotificationData([]);
        console.error(err.message);
      });
  };
  const updateStatus = async (notificationID) => {
    updateNotificationStatus(notificationID)
      .then((res) => {
        console.log(res);
        const newData = notificationData.filter(
          (item) => item.notificationID !== notificationID
        );
        setNotificationData(newData);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  const menu = (
    <div className="notifications-container">
      <div className="notification-header">
        <span className="title">Notifications</span>
        <span className="read-unread-box">
          <span
            style={{ fontWeight: unReadList && "bold" }}
            onClick={() => {
              setUnReadList(true);
            }}
          >
            UNREAD
          </span>
          <span
            style={{ fontWeight: !unReadList && "bold" }}
            onClick={() => {
              setUnReadList(false);
            }}
          >
            ALL
          </span>
        </span>
      </div>
      <List
        className="header-notifications-dropdown-user"
        itemLayout="horizontal"
        dataSource={
          unReadList
            ? notificationData.filter((item) => item.status === "unread")
            : notificationAllList
        }
        renderItem={(item) => (
          <div>
            <List.Item
              className="notification-list"
              onClick={() => {
                unReadList && updateStatus(item.notificationID);
              }}
            >
              <div
                className="notification-type"
                style={{
                  background:
                    item.type === "info"
                      ? "#aff3d7"
                      : item.type === "warn"
                      ? "#dfdf90"
                      : "#E65964",
                  color: "black",
                }}
              >
                {item.type === "info" ? (
                  <InfoCircleOutlined />
                ) : item.type === "warn" ? (
                  <WarningOutlined />
                ) : (
                  <AlertOutlined />
                )}
              </div>
              <div className="notification-box">
                <div className="notification-box-main">
                  <div className="title">{item.title}</div>
                  <div className="time">{formatTime(item.time)}</div>
                </div>
                <div className="description">{item.message}</div>
              </div>
            </List.Item>
          </div>
        )}
      />
    </div>
  );

  return (
    <div className="notification-page">
      <Badge size="small" count={badgeCount.length}>
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          placement="bottomRight"
          arrow
        >
          <a
            href="#pablo"
            className="ant-dropdown-link"
            onClick={(e) => e.preventDefault()}
          >
            {bell}
          </a>
        </Dropdown>
      </Badge>
    </div>
  );
};


export default Notification;
