
// import { useState } from "react";
import { Menu, Button } from "antd";
import { NavLink, useLocation } from "react-router-dom";
import logo from "../../assets/images/Logotype/Logotype-01.png";

function Sidenav({ color }) {
  const { pathname } = useLocation();
  const page = pathname.replace("/", "");

  const dashboard = [
    <svg
      width="18"
      height="18"
      viewBox="0 0 50 48.29"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <g>
        <rect class="cls-1" width="23.03" height="23.03" rx="7.84" ry="7.84" fill={color} />
        <rect class="cls-1" x="26.97" width="23.03" height="23.03" rx="7.84" ry="7.84" fill={color} />
        <rect class="cls-1" y="25.27" width="23.03" height="23.03" rx="7.84" ry="7.84" fill={color} />
        <rect class="cls-1" x="26.97" y="25.27" width="23.03" height="23.03" rx="7.84" ry="7.84" fill={color} />
      </g>
    </svg>,
  ];


  const track = [
    <svg
      width="18"
      height="18"
      viewBox="0 0 50 51.39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      key={0}
    >
      <g>
        <rect class="cls-1" x="17.73" width="14.54" height="51.39" rx="5.09" ry="5.09" fill={color} />
        <rect class="cls-1" x="35.46" y="11.34" width="14.54" height="40.05" rx="4.49" ry="4.49" fill={color} />
        <rect class="cls-1" y="25.88" width="14.54" height="25.51" rx="3.58" ry="3.58" fill={color} />
      </g>
    </svg>,
  ];
  const trade = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 63.3">
      <g id="Layer_1-2" data-name="Layer 1">
        <path class="cls-1" d="M49.24,18.49h-18.27V.55c.12.07.25.14.39.22h.02c3.38,1.93,11.88,7.47,17.83,17.68l.02.02s.01.01.01.02Z" fill={color} />
        <path class="cls-1" d="M28.99,20.73V0H10.45C4.68,0,0,4.68,0,10.43v42.45c0,5.75,4.68,10.43,10.45,10.43h29.11c5.77,0,10.45-4.68,10.45-10.43V20.73h-21.01ZM30.36,48.76c0,.94-.77,1.69-1.69,1.69H12.01c-.92,0-1.67-.75-1.67-1.69v-1.63c0-.94.75-1.69,1.67-1.69h16.67c.92,0,1.69.75,1.69,1.69v1.63ZM33.52,39.95c0,1-.83,1.82-1.82,1.82H12.14c-1,0-1.8-.83-1.8-1.82v-1.39c0-1,.81-1.8,1.8-1.8h19.56c1,0,1.82.81,1.82,1.8v1.39Z" fill={color} />
        <path class="cls-1" d="M49.24,18.49h-18.27V.55c.12.07.25.14.39.22h.02c3.38,1.93,11.88,7.47,17.83,17.68l.02.02s.01.01.01.02Z" fill={color} />
      </g>
    </svg>
  ]
  const journal = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 49.2">
      <path class="cls-1" d="M38.26,0h-5.32c-3.3,0-5.97,2.68-5.97,5.97v37.43h-3.93V5.97c0-3.3-2.68-5.97-5.97-5.97h-5.32C5.26,0,0,5.26,0,11.74v25.73c0,6.48,5.26,11.74,11.74,11.74h26.53c6.48,0,11.74-5.26,11.74-11.74V11.74c0-6.48-5.26-11.74-11.74-11.74ZM18.98,36.73c0,.82-.66,1.48-1.48,1.48H6.63c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM19.25,27.8c0,.82-.66,1.48-1.48,1.48H6.9c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM19.52,18.86c0,.82-.66,1.48-1.48,1.48H7.17c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM19.52,10.9c0,.82-.66,1.48-1.48,1.48H7.17c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM45.43,36.73c0,.82-.66,1.48-1.48,1.48h-10.87c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM45.7,27.8c0,.82-.66,1.48-1.48,1.48h-10.87c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM45.97,18.86c0,.82-.66,1.48-1.48,1.48h-10.87c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54ZM45.97,10.9c0,.82-.66,1.48-1.48,1.48h-10.87c-.82,0-1.48-.66-1.48-1.48v-.54c0-.82.66-1.48,1.48-1.48h10.87c.82,0,1.48.66,1.48,1.48v.54Z" fill={color} />
    </svg>
  ]
  const vision = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 51.36 51.36">
      <defs>
        <style>
          {`.cls-91 {
          fill: white;
          stroke-width: 0px;
        }
  
        .cls-92 {
          fill: white;
          stroke: white;
          stroke-miterlimit: 10;
          stroke-width: 2.54px;
        }`}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-92" d="M49.82,22.13c.18,1.16.27,2.34.27,3.55,0,13.49-10.92,24.41-24.41,24.41S1.27,39.17,1.27,25.68,12.19,1.27,25.68,1.27c1.74,0,3.44.18,5.07.53" />
          <path class="cls-92" d="M30.75,10.88c-1.58-.56-3.3-.85-5.07-.85-8.64,0-15.65,7.01-15.65,15.65s7.01,15.65,15.65,15.65,15.65-7.01,15.65-15.65c0-1.21-.14-2.41-.42-3.55" />
          <path class="cls-92" d="M28.11,18.6c-.75-.27-1.58-.41-2.43-.41-4.13,0-7.49,3.35-7.49,7.49s3.35,7.49,7.49,7.49,7.49-3.35,7.49-7.49c0-.58-.07-1.15-.2-1.7" />
          <path class="cls-91" d="M41,15.11l8.27-1.78c1.91-.41,2.65-2.74,1.33-4.19l-6.29-6.99c-1.35-1.47-3.79-.88-4.31,1.05l-2.49,8.45c-.23.84-.13,1.36.46,2l.64.7c.61.66,1.52.95,2.4.76Z" />
          <line class="cls-92" x1="39.69" y1="12.52" x2="20.49" y2="30.01" />
        </g>
      </g>
    </svg>
  ]
  const history = [
    <svg height="18" version="1.1" viewBox="0 0 20 20" width="18" xmlns="http://www.w3.org/2000/svg" ><title /><desc /><defs /><g fill="none" fill-rule="evenodd" id="Page-1" stroke="" stroke-width="0.8"><g fill="#000000" id="Core" opacity="1" transform="translate(-464.000000, -254.000000)"><g id="history" transform="translate(464.000000, 254.500000)"><path d="M10.5,0 C7,0 3.9,1.9 2.3,4.8 L0,2.5 L0,9 L6.5,9 L3.7,6.2 C5,3.7 7.5,2 10.5,2 C14.6,2 18,5.4 18,9.5 C18,13.6 14.6,17 10.5,17 C7.2,17 4.5,14.9 3.4,12 L1.3,12 C2.4,16 6.1,19 10.5,19 C15.8,19 20,14.7 20,9.5 C20,4.3 15.7,0 10.5,0 L10.5,0 Z M9,5 L9,10.1 L13.7,12.9 L14.5,11.6 L10.5,9.2 L10.5,5 L9,5 L9,5 Z" id="Shape" /></g></g></g></svg>
  ]
  const intelligence = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 67.58">
      <defs>
        <style>{`
      .cls-12 {
        fill: #9a9fae;
        stroke-width: 0px;
      }`}
        </style>
      </defs>
      <path class="cls-12" d="M36.95,56.68v6.08c0,.44-.35.79-.79.79h-3.05c.07.07.09.15.09.26v3.29c0,.26-.2.48-.46.48h-14.6c-.26,0-.48-.22-.48-.48v-3.29c0-.11.02-.2.09-.26h-3.16c-.42,0-.77-.35-.77-.79v-6.08c0-.42.35-.77.77-.77h21.58c.44,0,.79.35.79.77Z" />
      <path class="cls-12" d="M25,0C11.19,0,0,11.19,0,25v7.98c0,10.52,8.53,19.05,19.05,19.05h11.91c10.52,0,19.05-8.53,19.05-19.05v-7.98C50,11.19,38.81,0,25,0ZM40.25,31.38c-.68,1.9-1.68,3.62-2.98,5.16-.34.41-.76.55-1.26.45-1-.19-2-.4-3-.58-.15-.03-.35.02-.49.1-.73.41-1.46.83-2.18,1.26-.14.08-.28.22-.33.37-.35.96-.68,1.92-1,2.89-.17.5-.49.79-1.01.88-2,.37-4,.37-5.99,0-.52-.1-.84-.38-1.01-.88-.32-.97-.65-1.93-1-2.89-.05-.15-.2-.28-.34-.37-.72-.43-1.45-.85-2.18-1.26-.13-.07-.31-.12-.46-.1-1.01.18-2.02.39-3.03.58-.51.1-.92-.05-1.26-.45-1.29-1.54-2.29-3.24-2.97-5.13-.19-.53-.1-.97.27-1.39.67-.75,1.33-1.52,1.98-2.29.09-.11.14-.29.14-.45.01-.84.01-1.68,0-2.52,0-.15-.05-.34-.14-.45-.66-.78-1.33-1.55-2-2.32-.37-.41-.44-.85-.26-1.37.68-1.9,1.68-3.62,2.99-5.16.34-.39.74-.53,1.24-.43,1.01.2,2.02.4,3.03.58.14.03.33-.02.46-.09.73-.41,1.45-.85,2.19-1.25.18-.1.29-.21.35-.4.31-.92.65-1.82.94-2.74.2-.63.58-.97,1.23-1.04.6-.07,1.21-.16,1.81-.25h2.02c.08.02.16.04.24.05.54.07,1.08.14,1.63.2.6.07.97.39,1.15.97.31.95.64,1.89.97,2.83.05.14.18.27.31.35.72.43,1.45.85,2.18,1.26.15.08.35.14.52.11,1-.18,2-.38,3-.58.5-.1.9.04,1.24.43,1.31,1.54,2.3,3.26,2.99,5.16.19.52.11.96-.26,1.37-.67.76-1.33,1.52-1.98,2.29-.1.12-.16.31-.16.47-.02.84-.02,1.68,0,2.52,0,.15.05.33.15.44.66.78,1.33,1.55,2,2.32.36.4.44.83.26,1.34Z" />
      <path class="cls-12" d="M31.65,26.03c-.02,3.69-2.99,6.63-6.67,6.63-3.67-.02-6.63-2.99-6.63-6.65.02-3.69,2.99-6.65,6.67-6.65,3.69.02,6.63,3.01,6.63,6.67Z" />

    </svg>
  ]
  const calender = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 47.17">
      <defs>
        <style>
          {`.cls-21 {
          fill: #9a9fae;
          stroke-width: 0px;
        }`}
        </style>
      </defs>
      <g id="Layer_1-2" data-name="Layer 1">
        <g>
          <path class="cls-21" d="M50,15.93v.42H0v-.42C0,8.71,5.86,2.85,13.1,2.85h.91V0h2.74v2.85h16.69V0h2.74v2.85h.74c7.22,0,13.08,5.86,13.08,13.08Z" />
          <path class="cls-21" d="M0,19.28v20.09c0,4.31,3.49,7.8,7.8,7.8h34.4c4.31,0,7.8-3.49,7.8-7.8v-20.09H0ZM18.03,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM27.79,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47ZM37.55,34.59c0,.77-.62,1.39-1.39,1.39h-2.79c-.77,0-1.39-.62-1.39-1.39v-5.47c0-.77.62-1.39,1.39-1.39h2.79c.77,0,1.39.62,1.39,1.39v5.47Z" />
        </g>
      </g>
    </svg>
  ];

  const profile = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 50 52.68">
      <defs>
        <style>
          {` .cls-111 {
            fill: #9a9fae;
          stroke-width: 0px;
        }`}
        </style>
      </defs>
      <path class="cls-111" d="M23.35,0c1.1,0,2.2,0,3.29,0,.13.03.26.07.4.09.88.11,1.77.23,2.65.33.97.12,1.57.64,1.88,1.58.5,1.55,1.04,3.08,1.59,4.61.08.22.3.44.51.57,1.17.7,2.36,1.39,3.56,2.05.24.13.58.22.84.17,1.64-.29,3.26-.62,4.89-.94.81-.16,1.47.06,2.02.71,2.13,2.51,3.75,5.3,4.86,8.4.3.85.17,1.56-.42,2.23-1.09,1.23-2.17,2.47-3.23,3.73-.16.19-.25.51-.26.77-.03,1.37-.02,2.74,0,4.11,0,.25.08.54.24.72,1.07,1.27,2.16,2.53,3.26,3.77.58.66.72,1.36.42,2.19-1.11,3.1-2.73,5.9-4.85,8.41-.56.66-1.23.89-2.06.73-1.63-.32-3.26-.65-4.89-.94-.25-.04-.57.03-.8.16-1.2.66-2.38,1.35-3.55,2.05-.22.13-.46.36-.54.6-.57,1.56-1.11,3.13-1.63,4.71-.27.81-.8,1.28-1.64,1.43-3.26.6-6.51.6-9.76,0-.84-.16-1.37-.62-1.64-1.44-.52-1.58-1.06-3.15-1.63-4.71-.09-.24-.32-.46-.55-.6-1.17-.71-2.36-1.39-3.55-2.06-.21-.12-.51-.2-.75-.16-1.65.3-3.3.63-4.94.95-.83.16-1.5-.07-2.06-.73-2.11-2.5-3.72-5.28-4.83-8.36-.31-.87-.17-1.59.45-2.27,1.1-1.23,2.17-2.47,3.23-3.73.15-.18.23-.48.23-.73.02-1.37.02-2.74,0-4.11,0-.25-.08-.55-.23-.73-1.07-1.27-2.16-2.53-3.26-3.77-.6-.67-.72-1.38-.42-2.23,1.11-3.1,2.73-5.9,4.87-8.4.55-.64,1.21-.86,2.02-.7,1.65.32,3.29.65,4.94.95.23.04.53-.03.75-.15,1.2-.67,2.36-1.38,3.56-2.03.3-.16.47-.35.57-.66.5-1.49,1.06-2.97,1.52-4.47.32-1.03.95-1.57,2.01-1.69C21.39.29,22.37.14,23.35,0ZM24.97,37.17c5.99.01,10.83-4.8,10.85-10.78.02-5.99-4.78-10.83-10.77-10.86-6.01-.03-10.87,4.8-10.87,10.81,0,5.99,4.81,10.82,10.8,10.84Z" />

    </svg>
  ];


  return (
    <>
      <div className="brand">
        <img src={logo} alt="" />
      </div>
      <hr />
      <div style={{ fontSize: "12px", fontWeight: "600", color: "#858D9D", lineHeight: "20px", marginLeft: "10px", marginBottom: "10px" }}>MAIN MENU</div>
      <Menu theme="light" mode="inline">
        <Menu.Item key="1">
          <NavLink to="/performance-overview">
            <span
              className="icon"
              style={{
                background: page === "performance-overview" ? color : "",
              }}
            >
              {dashboard}
            </span>
            <span className="label">Performance Overview</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="11" disabled={true}>
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "track-report" ? color : "",
              }}
            >
              {track}
            </span>
            <span className="label">Track Record</span>
            <span className="soonTab">soon</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="22" >
          <NavLink to="/trade-report">
            <span
              className="icon"
              style={{
                background: page === "trade-report" ? color : "",
              }}
            >
              {trade}
            </span>
            <span className="label">Trade Report</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="23" disabled={true} >
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "trade-journal" ? color : "",
              }}
            >
              {journal}
            </span>
            <span className="label">Trade Journal</span>
            <span className="soonTab">soon</span>

          </NavLink>
        </Menu.Item>
        <Menu.Item key="24" disabled={true}>
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "vision-board" ? color : "",
              }}
            >
              {vision}
            </span>
            <span className="label">Vision Board</span>
            <span className="soonTab">soon</span>

          </NavLink>
        </Menu.Item>
        <Menu.Item key="24">
          <NavLink to="/trade-history">
            <span
              className="icon"
              style={{
                background: page === "trade-history" ? color : "",
              }}
            >
              {history}
            </span>
            <span className="label">Trade History</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="25" disabled={true}>
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "trade-intelligence" ? color : "",
              }}
            >
              {intelligence}
            </span>
            <span className="label">Trade Intelligence</span>
            <span className="soonTab">soon</span>

          </NavLink>
        </Menu.Item>
        <Menu.Item key="25" disabled={true}>
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "learning-hub" ? color : "",
              }}
            >
              {track}
            </span>
            <span className="label">Learning Hub</span>
            <span className="soonTab">soon</span>

          </NavLink>
        </Menu.Item>
        <Menu.Item key="26" disabled={true}>
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "economic-calender" ? color : "",
              }}
            >
              {calender}
            </span>
            <span className="label">Economic Calender</span>
            <span className="soonTab">soon</span>

          </NavLink>
        </Menu.Item>
        <Menu.Item key="27" disabled={true}>
          <NavLink to="coming-soon">
            <span
              className="icon"
              style={{
                background: page === "setting" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Setting</span>
            <span className="soonTab">soon</span>

          </NavLink>
        </Menu.Item>
        {/* <Menu.Item key="2">
          <NavLink to="/tables">
            <span
              className="icon"
              style={{
                background: page === "tables" ? color : "",
              }}
            >
              {tables}
            </span>
            <span className="label">Tables</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="3">
          <NavLink to="/billing">
            <span
              className="icon"
              style={{
                background: page === "billing" ? color : "",
              }}
            >
              {billing}
            </span>
            <span className="label">Billing</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="4">
          <NavLink to="/rtl">
            <span
              className="icon"
              style={{
                background: page === "rtl" ? color : "",
              }}
            >
              {rtl}
            </span>
            <span className="label">RTL</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item className="menu-item-header" key="5">
          Account Pages
        </Menu.Item>
        <Menu.Item key="6">
          <NavLink to="/profile">
            <span
              className="icon"
              style={{
                background: page === "profile" ? color : "",
              }}
            >
              {profile}
            </span>
            <span className="label">Profile</span>
          </NavLink>
        </Menu.Item> */}
        {/* <Menu.Item key="7">
          <NavLink to="/sign-in">
            <span className="icon">{signin}</span>
            <span className="label">Sign In</span>
          </NavLink>
        </Menu.Item>
        <Menu.Item key="8">
          <NavLink to="/sign-up">
            <span className="icon">{signup}</span>
            <span className="label">Sign Up</span>
          </NavLink>
        </Menu.Item> */}
      </Menu>
      <div className="aside-footer">
        <div
          className="footer-box"
        // style={{
        //   background: color,
        // }}
        >
          {/* <span className="icon" >
            {dashboard2}
          </span> */}
          <h6>Need Help?</h6>
          <p>Please check our docs</p>
          <Button type="primary" className="ant-btn-sm ant-btn-block">
            DOCUMENTATION
          </Button>
        </div>
      </div>
    </>
  );
}

export default Sidenav;
